import React from 'react';

import './Button.css';

interface ButtonProps {
  buttonText: string;
  onClick: () => void;
}

const ButtonComponent = (props: ButtonProps) => {
  const {
    buttonText,
    onClick,
  } = props;

  return (
    <button
      type="button"
      className="wallet-connect"
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}

export default ButtonComponent;