import React from 'react';
import {Page} from './Pages';
import Wallet from '../components/Wallet/Wallet';
import Connect from '../components/Wallet/Connect';

export default function WalletPage () {
  return (
    <Page>
      <Wallet />
    </Page>
  )
}