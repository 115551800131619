//@ts-nocheck
import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Action from "../Action/Action";
import { NAVBAR_HEIGHT } from "../NavBar/NavBar";
import {
  COLOR_WINE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_WINE,
  COLOR_PRIMARY,
  COLOR_BLUE,
  COLOR_TERARY
} from "../../helpers/styles";
import Card from "@material-ui/core/Card";

export const SIDE_DRAWER_WIDTH = 90;
export const SIDE_DRAWER_CLOSED_WIDTH = 90;

interface SideDrawerProps {
  sideDrawerOpen: boolean;
  onMenuButtonClick: (open: boolean) => void;
}

enum MenuLinkType {
  HOME = "dashboard",
  GALLERY = "gallery",
  MOMENTS = "moments",
  WALLET = "wallet",
  HELP = "help",
}

const MENU_CONTENT: { [key: string]: any } = {
  [MenuLinkType.HOME]: {
    title: "Home",
    icon: "pe-7s-home pe-lg",
    href: "/home",
  },
  [MenuLinkType.GALLERY]: {
    title: "Gallery",
    icon: "pe-7s-photo-gallery",
    href: "/gallery",
  },
  [MenuLinkType.MOMENTS]: {
    title: "Moments",
    icon: "pe-7s-diamond",
    href: "/moments",
  },
  [MenuLinkType.WALLET]: {
    title: "Wallet",
    icon: "pe-7s-wallet",
    href: "/wallet",
  },
  // [MenuLinkType.HELP]: {
  //   title: "FAQ",
  //   icon: "pe-7s-help1",
  //   href: "/faq",
  // },
};

const MENUS = [
  MenuLinkType.HOME,
  MenuLinkType.GALLERY,
  MenuLinkType.MOMENTS,
  MenuLinkType.WALLET,
  // MenuLinkType.HELP,
];

const useStyles = makeStyles(
  (theme: Theme) => ({
    sideDrawer: {
      height: "100vh",
      //position: 'fixed',
      zIndex: 1101,
      top: `${NAVBAR_HEIGHT}px`,
      left: 0,
      boxShadow: "0 0 40px rgb(0,0,0,.5)",
    },
    collapsed: {
      boxShadow: "none",
      // display: 'none',

      "& $header": {
        padding: "10px 20px",
        justifyContent: "center",
      },

      "& $userId": {
        marginTop: theme.spacing(4),
      },

      "& $rankCard": {
        marginTop: 0,
      },

      "& $adminToggle": {
        display: "none",
      },

      "& $menuActionIcon": {
        margin: "0 auto",
      },

      "& $logoutAction": {
        margin: "0 auto",
      },
      "& $logoutActionIcon": {
        margin: "0 auto",
      },
    },

    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: SIDE_DRAWER_WIDTH,
      maxWidth: "100%",
      border: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `${SIDE_DRAWER_CLOSED_WIDTH}px`,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },

    logo: {
      width: "75px",
    },

    messagesIcon: {
      width: "32px",
      height: "32px",
    },

    menu: {
      padding: 0,
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
    },
    menuAction: {
      whiteSpace: "normal",
      padding: theme.spacing(2, 2.5),
      position: "relative",
      fontSize: theme.typography.pxToRem(16),
      color: COLOR_TEXT,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      letterSpacing: "0.6",

      "&:hover": {
        textDecoration: "none",
        backgroundColor: COLOR_TERARY,
        color: COLOR_PRIMARY,
        transition: "all 0.3s ease",
      },
    },
    menuActionText: {
      fontWeight: 600,
      // paddingTop: theme.spacing(0.5),
    },
    menuActionSelected: {
      color: COLOR_WHITE,
      backgroundColor: COLOR_PRIMARY,
      boxShadow: `0 0 25px 0 ${COLOR_BLUE}`,

      "&:hover": {
        color: COLOR_WHITE,
        backgroundColor: COLOR_PRIMARY,
      },
    },
    menuActionIcon: {
      flexShrink: 0,
      marginRight: 0,
      fontSize: "20px",
      fontWeight: 800,
    },

    logout: {
      marginTop: "auto",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2, 2.5),
    },
    logoutAction: {
      padding: 0,
      color: COLOR_DARK_GRAY,
      fontSize: theme.typography.pxToRem(16),

      "&:hover": {
        textDecoration: "none",
      },
    },
    logoutActionIcon: {
      width: "32px",
      height: "32px",
      marginRight: theme.spacing(3),
      flexShrink: 0,
    },
    card: {
      //height: "10vh",
      position: "fixed",
      bottom: 0,
      background: "#f0f0f0f",
      width: "100%",
      zIndex: 1101,
      overflow: "auto"
    },
  }),
  { name: SideDrawerSM.name }
);

export default function SideDrawerSM(props: SideDrawerProps) {
  const classes = useStyles();
  const { sideDrawerOpen, onMenuButtonClick } = props;

  const [sideDrawerMenuLinks, setSideDrawerMenuLinks] =
    useState<MenuLinkType[]>(MENUS);

  const drawerContent = (
    <>
      <ul className={classes.menu}>
        {sideDrawerMenuLinks.map((menuLink: MenuLinkType) => (
          <li key={menuLink}>
            <Action
              className={classes.menuAction}
              selectedClassName={classes.menuActionSelected}
              iconClassName={classes.menuActionIcon}
              href={MENU_CONTENT[menuLink].href}
            >
              <span className={classes.menuActionIcon}>
                <i className={MENU_CONTENT[menuLink].icon}></i>
              </span>
              <span className={classes.menuActionText}>
                {MENU_CONTENT[menuLink].title}
              </span>
            </Action>
          </li>
        ))}
      </ul>

      {/* <div className={classes.logout}>
        <Action
          className={classes.logoutAction}
          selectedClassName={classes.menuActionSelected}
          iconClassName={classes.menuActionIcon}
          icon={ExitToAppOutlinedIcon}
          onClick={() => {}}
        >
          <span className={classes.menuActionText}>Logout</span>
        </Action>
      </div> */}
    </>
  );

  const drawerClassName = clsx(
    classes.sideDrawer,
    !sideDrawerOpen && classes.collapsed
  );

  return (
        <Card className={classes.card}>{drawerContent}</Card>

    // <Drawer
    //   variant='permanent'
    //   anchor={'bottom'}
    //   className={drawerClassName}
    //   classes={{
    //     paper: clsx(classes.drawerPaper, !sideDrawerOpen && classes.drawerPaperClose),
    //   }}
    //   open={sideDrawerOpen}
    // >
    //   {drawerContent}
    // </Drawer>
  );
}
