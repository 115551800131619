import React from 'react';
import SignUpContainer from './SignUpContainer';

export const SignUp = (props: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

      if(width  <= 959){
          return <SignUpContainer page='sm' {...props} />
      } else {
        return <SignUpContainer  page='lg' {...props} />
      }
}