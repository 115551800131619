import React from 'react';
import {Page} from './Pages';
import WalletWrapper from '../components/Wallet/WalletWrapper';

export default function WalletWrapperPage () {
  return (
    <Page>
      <WalletWrapper />
    </Page>
  )
}