import React from 'react';
import { Page } from './Pages';
import { Profile } from '../components/Profile/Profile';

export default function ProfilePage () {
  return (
    <Page>
      <Profile />
    </Page>
  )
}