import { User, UserVerification, UserSettings }  from '../../types/User';

export const SET_USER = 'SET_USER';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_VERIFICATION = 'SET_USER_VERIFICATION';

export function setUser(user:User) {
  return {
    type: SET_USER,
    user
  }
}

export function setUserSettings(userSettings:UserSettings) {
  return {
    type: SET_USER_SETTINGS,
    userSettings
  }
}

export function setUserVerification(userVerification:UserVerification) {
  return {
    type: SET_USER_VERIFICATION,
    userVerification
  }
}