import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  COLOR_DARK_GRAY,
  COLOR_SECONDARY,
  COLOR_WHITE,
  COLOR_PRIMARY
} from '../../helpers/styles';
import DialogContainer from '../Dialog/DialogContainer';

const useStyles = makeStyles(theme => ({
  productDetailWrapper: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: "82.4px",

  },
  pageHeader:{
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },
  productContainer: {
    // boxShadow: `0 10px 10px 0 ${COLOR_DARK_GRAY}`,
    boxShadow: `0 3px 7px 0 ${COLOR_DARK_GRAY}`,
    padding: theme.spacing(3),
    display: 'flex',
    borderRadius:"30px"
  },
  productDetail: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  productImage:{
    height: 'auto',
    width: '500px',
  },
  productName: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid rgba(0,0,0,.1);`,
    marginBottom: theme.spacing(2),
  },
  productDescription: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(1.5, 0),
    lineHeight: '1.7',
    letterSpacing: '.7px',
    borderBottom: `1px solid rgba(0,0,0,.1);`,
    // marginBottom: theme.spacing(2),
  },
  buyNowButton: {
    width: '100px',
    background: COLOR_SECONDARY,
    color: COLOR_PRIMARY,
    borderColor: COLOR_PRIMARY,
    marginTop: theme.spacing(2),

    '&:hover': {
      background: COLOR_PRIMARY,
      color: COLOR_WHITE,
    }
  },
  price:{
    display: 'flex',
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,

    '&> span': {
      marginRight: '15px',
    },

    '&> strong': {
      fontWeight: 700,
    }
  },
  category: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,
  },
  data: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    fontSize: '14px',

    '&>div': {
      width: '50%',
    }
  },
  loading: {

  }
}), { name: ProductDetails.name });

export default function ProductDetails() {
  const classes = useStyles();

  return (
    <div className={classes.productDetailWrapper}>
      <div className={classes.pageHeader}>
        <div className={classes.headerTitle}>
          Home
        </div>
        <div className={classes.subHeaderTitle}>
          England Cricket Board Marketplace
        </div>
      </div>
      <Paper className={classes.productContainer}>
        <div className={classes.productDetail}>
          <div className={classes.productName}>Sample Card</div>
          <div className={classes.productDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
        </div>
      </Paper>

      <DialogContainer />
    </div>
  )
}
