//@ts-nocheck

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Button from "../Button";
import {
  PAPER_WHITE,
  COLOR_WINE,
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
} from "../../../../helpers/styles";
import { FONT_NUNITO } from "../../../../styles/fonts";

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
  },
  timelineContentWrapper: {
    //boxShadow: `0 10px 10px 0 ${COLOR_DARK_GRAY}`,
    backgroundColor: PAPER_WHITE,
    padding: theme.spacing(2),
    width: "40%",
    borderRadius: 20,
    minHeight: 400,
    paddingTop: theme.spacing(3),
  },
  paperHeader: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    fontWeight: 700,
  },
  paperSubHeaderline: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(18),
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  submitWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

    "@media (max-width: 991px)": {
      flexDirection: "column",
    },
  },
  submitButton: {
    backgroundColor: COLOR_WINE,
    borderRadius: "5px",
    color: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2, 6),
    width: "auto",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
}));

interface VerifyProps {
  onPhoneVerifyClick?(phoneOTP): voidl;
}

export default function PhoneVerifyLG(props: VerifyProps) {
  const classes = useStyles();
  const { onPhoneVerifyClick } = props;

  const [currentState, setCurrentState] = useState<string>("");
  const [phoneOTP, setPhoneOTP] = useState<number>();
  const [phoneNo, setPhoneNo] = useState<number>();
  const [OTPError, setOTPError] = useState<boolean>(false);

  function validatePhNo(phone) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return re.test(phone);
  }

  function validOTP(otp) {
    const regex = /^[0-9]{4}$/;
    return regex.test(otp);
  }

  const onPhoneSubmitClick = () => {
    if (phoneNo && validatePhNo(phoneNo)) {
      setCurrentState("verifyPhone");
    }
  };

  const onPhoneOTPChange = (value) => {
    if (OTPError) {
      setOTPError(false);
    }
    setPhoneOTP(value);
  };

  const onVerifyClick = () => {
    if (validOTP(phoneOTP)) {
      onPhoneVerifyClick && onPhoneVerifyClick(phoneOTP);
    } else {
      !OTPError && setOTPError(true);
    }
  };

  if (currentState === "verifyPhone") {
    return (
      <div className={classes.paperWrapper}>
        <Paper className={classes.timelineContentWrapper}>
          <div className={classes.paperHeader}>Verify OTP</div>
          <div className={classes.paperSubHeaderline}>
            Please enter 4 digit code sent to your phone number
          </div>
          <div className={classes.inputContainer}>
            <OtpInput
              value={phoneOTP}
              onChange={onPhoneOTPChange}
              numInputs={4}
              isInputNum
              separator={<span> </span>}
              containerStyle={{
                justifyContent: "center",
                alignItems: "center",
              }}
              inputStyle={{
                width: 40,
                height: 40,
                padding: 10,
                margin: 10,
                fontSize: 16,
                border: "1px solid rgba(0, 0, 0, 0.3)",
              }}
            />
          </div>
          {OTPError && (
            <div className={classes.errorMessage}>Enter valid 4 digit OTP</div>
          )}
          <div className={classes.submitWrapper}>
            <Button
              primary
              className={classes.submitButton}
              type="submit"
              onClick={onVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Paper>
      </div>
    );
  } else {
    return (
      <div className={classes.paperWrapper}>
        <Paper className={classes.timelineContentWrapper}>
          <div className={classes.paperHeader}>
            Please enter your phone number to receive a verification code
          </div>
          <div className={classes.inputContainer}>
            <FormLabel required>Phone Number</FormLabel>
            <PhoneInput
              country={"in"}
              value={phoneNo}
              onChange={(phone) => setPhoneNo(phone)}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              inputStyle={{
                minHeight: "50px",
                width: "100%",
              }}
            />
          </div>
          <div className={classes.submitWrapper}>
            <Button
              primary
              className={classes.submitButton}
              type="submit"
              onClick={onPhoneSubmitClick}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}
