import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLOR_LIGHT_WINE, COLOR_WINE , COLOR_PRIMARY,COLOR_SECONDARY,COLOR_TERARY} from '../../helpers/styles';

interface LoaderProps {
  inProgress: boolean;
}

const useStyles = makeStyles(theme => ({
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLOR_TERARY,
    zIndex: 1000,
  },
  spinner: {
    color: COLOR_PRIMARY
  }
}), { name: Loader.name });

export default function Loader(props:LoaderProps) {
  const classes = useStyles();
  const { inProgress } = props;

  return (
    <Fade
      in={inProgress}
      style={{ transitionDelay: '0' }}
      unmountOnExit
    >
      <div className={classes.loader}>
        <CircularProgress classes={{ root: classes.spinner }} />
      </div>
    </Fade>
  )
}