//@ts-nocheck
import React from 'react';
import ProductDetailsLG  from './ProductDetails.lg';
import ProductDetailsSM from './ProductDetails.sm';
export const ProductDetails = (props: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  
      if(width  <= 959){
          return <ProductDetailsSM {...props} />
      } else {
        return <ProductDetailsLG {...props} />
      }
}