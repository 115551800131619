import { User, UserVerification, UserSettings }  from '../../types/User';
import {
  SET_USER,
  SET_USER_SETTINGS,
  SET_USER_VERIFICATION
} from '../actions/userAction';

export interface UserState extends User, UserVerification, UserSettings {}

export function getUserInitialState () {
  return INITIAL_STATE;
}

const INITIAL_STATE:UserState = {} as UserState;


export default function userReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.userSettings
      };
    case SET_USER_VERIFICATION:
      return {
        ...state,
        userVerification: action.userVerification
      };
    default:
      return state;
  }
};