//@ts-nocheck
import React from "react"
import { makeStyles} from '@material-ui/core/styles';
import Action from './Action';

import { COLOR_TEXT, FONT_NUNITO} from '../../helpers/styles';
import ecbLogo from '../../assets/images/ecbLogo.png';
import {useHistory} from "react-router-dom";
import LandingContent from './LandingContent';
import LandingMenu from './LandingMenu';
import LandingHamburger from './LandingHamburger';

const useStyles = makeStyles(theme => ({
  landingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    '@media (max-width: 991px)': {
      background: `linear-gradient(189deg, rgba(255,238,247,1) 39%, rgba(210,210,210,0.8057598039215687) 85%)`,
    }
  },
  landingHead: {
    margin: '40px 100px 0 50px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',

    '@media (max-width: 991px)': {
      margin: '20px',
    }
  },
  headLeft: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  helmetLogo: {
    width: '40px',
    height:'40px',
  },
  laraHeading: {
    color: COLOR_TEXT,
    fontWeight: 700,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    margin: theme.spacing(0, 4),
  },
}), { name: LandingContainer.name });


export default function LandingContainer(props:any) {
  const classes = useStyles();
  const history = useHistory();
  const pageSize = props.page;

  return (
    <div className={classes.landingWrapper}>
      <div className={classes.landingHead}>
        <div className={classes.headLeft}>
          <img className={classes.helmetLogo} src={ecbLogo} alt='ecb_logo'/>
          { pageSize == 'lg' ? <div className={classes.laraHeading}> ECB MARKETPLACE </div> : '' }
        </div>
        <div className={classes.headRight}>
          { pageSize == 'lg' ?
            <LandingMenu />
          : <LandingHamburger />
          }
        </div>

      </div>
      <LandingContent pageSize={pageSize}/>
    </div>
  );
}
