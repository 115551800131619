import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  COLOR_PRIMARY,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_WINE_1,
  COLOR_WINE_2,
  COLOR_LIGHT_WINE,
  COLOR_WINE,
  FONT_NUNITO,
  COLOR_SECONDARY
} from '../../helpers/styles';

interface ButtonProps {
  className?: string;
  type?: 'button' | 'submit';
  children: any;
  primary?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    appearance: 'none',
    background: COLOR_PRIMARY,
    backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    borderColor: COLOR_PRIMARY,
    borderRadius: '10px',
    color: COLOR_WHITE,
    cursor: 'pointer',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1,
    outlineColor: COLOR_TEXT,
    padding: theme.spacing(1.5),
    textDecoration: 'none',
    transition: 'background 0.2s, color 0.2s, border-color 0.2s',


    '&:hover': {
      borderColor: COLOR_PRIMARY,
      background: COLOR_PRIMARY,
      backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
      color: COLOR_WHITE,
    },
  },
  primary: {
    borderColor: COLOR_PRIMARY,
    background: COLOR_PRIMARY,
    color: COLOR_WHITE,

    '&:hover': {
      borderColor: COLOR_PRIMARY,
      background: COLOR_PRIMARY,
    },
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',

    '&:hover': {
      color: COLOR_WHITE,
    },
  }
}), { name: DialogButton.name });

export default function DialogButton (props: ButtonProps) {
  const {
    className,
    type = 'button',
    children,
    primary = false,
    disabled = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(
        classes.button,
        primary && classes.primary,
        className,
        disabled && classes.disabled,
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
