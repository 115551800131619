import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {
  COLOR_WHITE,
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_PRIMARY,
  COLOR_DARK_GRAY,
  FONT_NUNITO
} from '../../helpers/styles';
import { isVerifiedEmail, isVerifiedWallet } from '../../helpers/user';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  profileConatiner: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    padding: '50px 0px',
    width: '50%',

    '@media (max-width: 1025px)': {
      width: '85%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  card: {
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderColor: COLOR_WHITE,
    backdropFilter:"blur(20px)",
    borderRadius: 30,
    boxShadow: `0 0 25px 0 ${COLOR_SHADOW}`,
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: 'normal',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  userName: {
    color: COLOR_PRIMARY,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
  },
  userInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  avatar : {
    color: COLOR_PRIMARY,
    width: '80px',
    height: '80px',

    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(70),
    },
  },
  table: {
    borderRadius: '20px',
    fontFamily: FONT_NUNITO,
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  cellHead: {
    borderBottom: 'none',
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    padding: theme.spacing(0.5, 2),
  },
  cell: {
    borderBottom: 'none',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    padding: theme.spacing(0.5, 2),
  },
  errorCase: {
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
  },
}), { name: ProfileConatiner.name });

export function ProfileConatiner (props:any) {
  const classes = useStyles();
  const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);

  return (
    <div className={classes.profileConatiner}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          { userVerified && userVerified.email ?
            <div className={classes.userInfo}>
              <div className={classes.avatar}>
                <AccountCircleIcon/>
              </div>

              <div className={classes.userName}>Name:&nbsp;&nbsp; {userVerified.name} </div>

              <TableContainer className={classes.table} component={Paper}>
                <Table>
                  <TableBody>
                  <TableRow>
                      <TableCell className={classes.cellHead}>Email-Id</TableCell>
                      <TableCell className={classes.cell}>{userVerified.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellHead}>Account-Id</TableCell>
                      <TableCell className={classes.cell}>{userVerified.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellHead}>Account-address</TableCell>
                      <TableCell className={classes.cell}>{userVerified.address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellHead}>Email-Verified</TableCell>
                      <TableCell className={classes.cell}>{isVerifiedEmail() ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell className={classes.cellHead}>Wallet-Verified</TableCell>
                      <TableCell className={classes.cell}>{isVerifiedWallet() ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          :
            <span className={classes.errorCase}> Information not available, please create an account </span>
          }
        </CardContent>
      </Card>
    </div>
  )
}


export default ProfileConatiner;
