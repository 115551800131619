import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DialogButton from './DialogButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  COLOR_WINE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
  FONT_NUNITO
} from '../../helpers/styles';

interface DialogProps {
  className?: string;
  contentClassName?: string;
  open: boolean;
  title?: string;
  subTitle?: string;
  text?: any;
  actions?: DialogAction[];
  children?: any;
  onClose: () => void;
}

interface DialogAction {
  name: string;
  disabled?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    position:'absolute',
    top:'10%',
    margin: '0 auto',
    overflow:'scroll',
    height:'100%',
    display:'block'
  },
  backdrop: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  paper: {
    maxWidth: '450px',
    margin: theme.spacing(2.5, 'auto'),
    boxShadow: theme.shadows[5],
    outline: 'none',
    overflow: 'auto',

    '@media (max-width: 450px)': {
      maxWidth: '320px',
    }
  },

  header: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    borderRadius: '8px 8px 0 0',
    position: 'relative',
  },
  headerWrapper: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  headerTitle: {
    margin: 0,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: 1.3,
    fontWeight: 600,
    textAlign: 'left',
    letterSpacing: 0,
    color: COLOR_TEXT,
  },

  contentSection: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: COLOR_WHITE,
    borderRadius: '0 0 8px 8px',
  },

  subTitle: {
    margin: 0,
    fontFamily: FONT_NUNITO,
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.3,
    color: COLOR_TEXT,
    textAlign: 'center',
  },
  subTitleWrapper: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'justify',
  },
  text: {
    margin: 0,
    fontFamily: FONT_NUNITO,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '24px',
    color: COLOR_TEXT,
    padding: theme.spacing(3, 6),
    textAlign: 'center',

    '@media (max-width: 450px)': {
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(3, 0),
    }
  },
  textWrapper: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    padding: theme.spacing(2, 0),
    textAlign: 'justify',
  },

  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '&:empty': {
      display: 'none',
    },
  },
  action: {
    width: '90%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),

    '&:first-of-type': {
      marginTop: 0,
    },
  },

  dialogButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR_WINE}`,
    borderRadius: '25px',
    color: COLOR_WHITE,
    display: 'flex',
    fontSize: theme.typography.pxToRem(20),
    justifyContent: 'center',
    padding: '14px',
    opacity: 1,
  },

  closeButton: {
    position: 'absolute',
    right: '20px',
    padding: 0,
    appearance: 'none',
    background: 'none',
    border: `1px solid transparent`,
    borderRadius: '50%',
    color: COLOR_DARK_GRAY,
    cursor: 'pointer',
    transition: 'color 0.3s, border-color 0.3s',
    boxSizing: 'border-box',

    '&:hover': {
      color: COLOR_WINE,
    },

    '&:focus': {
      outline: 0,
      borderColor: COLOR_DARK_GRAY,
    },

    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(30)
    }
  },
}), { name: Dialog.name });

export default function Dialog (props: DialogProps) {
  const {
    className,
    contentClassName,
    open = false,
    title = '',
    text = '',
    subTitle= '',
    actions = [],
    children,
    onClose,
  } = props;
  const classes = useStyles();

  return (
    <MaterialModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.dialog}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop
      }}
      onBackdropClick={onClose}
    >
      <Fade in={open}>
        <div className={clsx(classes.paper, className)}>
          <div className={clsx(classes.header, title.length ? classes.headerWrapper : '' )}>
            <h2
              id='transition-modal-title'
              className={classes.headerTitle}
            >
              {title}
            </h2>

            <button
              className={classes.closeButton}
              onClick={onClose}
            >
              <HighlightOffIcon />
            </button>
          </div>

          <div
            className={classes.contentSection}
            id='transition-modal-description'
          >

            {subTitle && (
              <p
                className={clsx(classes.subTitle, title.length ? classes.subTitleWrapper : '')}
              >{subTitle}</p>
            )}

            {text && (
              <p
              className={clsx(classes.text, title.length ? classes.textWrapper : '')}
              >
                {text}
              </p>
            )}

            {children}

            {actions && (
              <div className={classes.actions}>
                {actions.map((action, index) => (
                  <DialogButton
                    key={index}
                    className={clsx(classes.action, classes.dialogButton)}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >
                    {action.name}
                  </DialogButton>
                ))}
              </div>
            )}
          </div>
        </div>
      </Fade>
    </MaterialModal>
  );
}
