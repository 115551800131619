//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import Button from "@material-ui/core/Button";
import {
  COLOR_LIGHT_GRAY_2,
  COLOR_LIGHT_GRAY_3,
  COLOR_WHITE,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  BOX_SHADOW_1
} from "../../../helpers/styles"
import Typography from "@material-ui/core/Typography";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
      `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
      `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: COLOR_LIGHT_GRAY_3,
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: COLOR_LIGHT_GRAY_2,
    zIndex: 1,
    color:  COLOR_WHITE,
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
    `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    boxShadow: `0 4px 10px 0 ${BOX_SHADOW_1}`,
  },
  completed: {
    backgroundImage:
    `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <EmailIcon />,
    2: <SmartphoneIcon />,
    3: <AccountBalanceWalletIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Select campaign settings...";
    case 1:
      return "What is an ad group anyways?";
    case 2:
      return "This is the bit I really care about!";
    default:
      return "Unknown step";
  }
}

interface timelineProps {
  active: number
  steps?: string[];
  renderItem?: React.ReactNode;
}

export default function IconTimeline(props: timelineProps) {
  const classes = useStyles();
  const {
    steps,
    active,
    renderItem
  } = props;

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={active}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {renderItem}
      </div>
    </div>
  );
}
