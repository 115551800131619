import React from 'react';
import {Page} from './Pages';
import {TimelineWrapper} from '../components/Timeline/TimelineWrapper';

export default function TimelinePage () {
  return (
    <Page>
      <TimelineWrapper />
    </Page>
  )
}