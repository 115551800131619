//@ts-nocheck
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";

import Input from "../Input";
import Button from "../Button";
import {
  PAPER_WHITE,
  COLOR_WINE,
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
} from "../../../../helpers/styles";
import { FONT_NUNITO } from "../../../../styles/fonts";
import OtpInput from "react-otp-input";


const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
  },
  timelineContentWrapper: {
    //boxShadow: `0 10px 10px 0 ${COLOR_DARK_GRAY}`,
    backgroundColor: PAPER_WHITE,
    padding: theme.spacing(2),
    // width: "40%",
    borderRadius: 20,
    minHeight: 400,
    paddingTop: theme.spacing(3),
  },
  paperHeader: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    fontWeight: 700,
  },
  paperSubHeaderline: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(18),
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  input: {
    marginTop: theme.spacing(1),
    borderRadius: "4px",
  },
  submitWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

    "@media (max-width: 991px)": {
      flexDirection: "column",
    },
  },
  submitButton: {
    backgroundColor: COLOR_WINE,
    borderRadius: "5px",
    color: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2, 6),
    width: "auto",
    background: COLOR_SECONDARY,
    color: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    fontWeight: 700,

    '&:hover': {
      borderColor: COLOR_PRIMARY,
      backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
      color: COLOR_WHITE,
    },
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
}));

interface VerifyProps {
  onEmailVerifyClick?(emailOTP): void;
}

export default function EmailVerifySM(props: VerifyProps) {
  const classes = useStyles();
  const {
    onEmailVerifyClick
  } = props;

  const [email, setEmail] = useState<string>("");
  const [emailAddressError, setEmailAddressError] = useState<string>("");
  const [currentState, setCurrentState] = useState<string>("");
  const [emailOTP, setEmailOTP] = useState('');
  const [OTPError, setOTPError] = useState<boolean>(false);

  const onEmailChange = (value) => {
    if(emailAddressError){
      setEmailAddressError('')
    }
    setEmail(value);
  };

  function validOTP(otp) {
    const regex = /^[0-9]{4}$/;
    return regex.test(otp);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const onEmailSubmitClick = () => {
    if (email && validateEmail(email)) {
        emailAddressError && setEmailAddressError("");
        setCurrentState("verifyEmail");
      } else if (!validateEmail(email)) {
        setEmailAddressError("Enter valid email address");
      }
  };

  const onOTPChange = (value) => {
    if (OTPError) {
      setOTPError(false);
    }
    setEmailOTP(value);
  };

  const onVerifyClick = () => {
    if (validOTP(emailOTP)) {
      onEmailVerifyClick && onEmailVerifyClick(emailOTP);
    } else {
      !OTPError && setOTPError(true);
    }
  };



  if (currentState === "verifyEmail") {
    return (
      <div className={classes.paperWrapper}>
        <Paper className={classes.timelineContentWrapper}>
          <div className={classes.paperHeader}>Verify OTP</div>
          <div className={classes.paperSubHeaderline}>
            Please enter 4 digit code sent to your email address
          </div>
          <div className={classes.inputContainer}>
            <OtpInput
              value={emailOTP}
              onChange={onOTPChange}
              numInputs={4}
              isInputNum
              separator={<span> </span>}
              containerStyle={{
                justifyContent: "center",
                alignItems: "center",
              }}

              inputStyle={{
                width: 40,
                height: 40,
                padding: 10,
                margin: 10,
                fontSize: 16,
                border: "1px solid rgba(0, 0, 0, 0.3)",
              }}
            />
          </div>
          {OTPError && (
            <div className={classes.errorMessage}>Enter valid 4 digit OTP</div>
          )}
          <div className={classes.submitWrapper}>
            <Button
              primary
              className={classes.submitButton}
              type="submit"
              onClick={onVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Paper>
      </div>
    );
  } else {
    return (
      <div className={classes.paperWrapper}>
        <Paper className={classes.timelineContentWrapper}>
          <div className={classes.paperHeader}>
          Verify your email with OTP to continue
          </div>
          <div className={classes.inputContainer}>
            <FormLabel required>Email Address</FormLabel>
            <Input
              className={classes.input}
              label="Email"
              value={email}
              error={emailAddressError}
              onChange={(value: string) => onEmailChange(value)}
              //onBlur={(value: string) => handleAssetNameBlur(value)}
            />
          </div>
          <div className={classes.submitWrapper}>
            <Button
              primary
              className={classes.submitButton}
              type="submit"
              onClick={onEmailSubmitClick}
            >
              Get OTP
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}
