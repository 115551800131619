import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import WalletPage from '../pages/WalletPage';
import WalletWrapperPage from '../pages/WalletWrapperPage';
import GalleryPage from '../pages/GalleryPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import TransactionHistoryPage from '../pages/TransactionHistoryPage';
import TimelinePage from '../pages/TimelinePage';
import LandingPage from '../pages/LandingPage';
import ScrollToTop from '../pages/ScrollToTop';
import SignUpPage from '../pages/SignUpPage';
import LoginPage from '../pages/LoginPage';
import VerificationPage from '../pages/VerificationPage';
import RazorPayPage from '../pages/RazorPayPage';
import ProfilePage from '../pages/ProfilePage';

export default function Router () {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Redirect exact from='/' to='/home' />
        <Route path='/home'>
          <DashboardPage />
        </Route>
        <Route exact path='/login'>
          <LoginPage />
        </Route>
        <Route exact path='/landing'>
          <LandingPage />
        </Route>
        <Route exact path='/signup'>
          <SignUpPage />
        </Route>
        <Route exact path='/profile'>
          <ProfilePage />
        </Route>
        <Route exact path='/razorpay'>
          <RazorPayPage />
        </Route>
        <Route path='/product-details/:productId'>
          <ProductDetailsPage />
        </Route>
        <Route exact path='/gallery'>
          <Redirect exact from='/gallery' to='/gallery/diamond' />
          <GalleryPage />
        </Route>
        <Route exact path='/gallery/:tab'>
          <GalleryPage />
        </Route>
        {/* <Route exact path='/gallery/:playerId'>
          <FlashCardsPage />
        </Route> */}
        <Route path='/wallet'>
          <WalletWrapperPage />
        </Route>
        <Route path='/wallet-connect'>
          <WalletPage />
        </Route>
        <Route path='/orders'>
          <TransactionHistoryPage />
        </Route>
        <Route path='/faq'>
          <DashboardPage />
        </Route>
        <Route exact path='/moments'>
          <Redirect exact from='/moments' to='/moments/1' />
          <TimelinePage />
        </Route>
        {/* <Route exact path='/moments/:productId'>
          <TimelinePage />
        </Route> */}
        <Route exact path='/moments/:active'>
          <TimelinePage />
        </Route>
        <Route exact path='/verification'>
          <VerificationPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
