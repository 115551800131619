import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  COLOR_PRIMARY,
  COLOR_DARK_GRAY,
  COLOR_TEXT,
  COLOR_WHITE,
  FONT_NUNITO
} from '../../helpers/styles';

interface ButtonProps {
  className?: string;
  type?: 'button' | 'submit';
  children: any;
  primary?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '160px',
    appearance: 'none',
    padding: theme.spacing(1.5),
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    border: `2px solid ${COLOR_DARK_GRAY}`,
    borderRadius: '6px',
    background: COLOR_WHITE,
    color: COLOR_TEXT,
    cursor: 'pointer',
    outlineColor: COLOR_TEXT,
    textDecoration: 'none',
    transition: 'background 0.2s, color 0.2s, border-color 0.2s',

    '&:hover': {
      borderColor: COLOR_TEXT,
      background: COLOR_TEXT,
      color: COLOR_WHITE,
    },
  },
  primary: {
    borderColor: COLOR_PRIMARY,
    background: COLOR_PRIMARY,
    color: COLOR_WHITE,

    '&:hover': {
      borderColor: COLOR_PRIMARY,
      background: COLOR_PRIMARY,
    },
  },
  disabled: {
    opacity: '0.6',
    cursor: 'not-allowed',

    '&:hover': {
      color: COLOR_WHITE,
    },
  }
}), { name: LoginButton.name });

export default function LoginButton (props: ButtonProps) {
  const {
    className,
    type = 'button',
    children,
    primary = false,
    disabled = false,
    onClick = () => {},
  } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(
        classes.button,
        primary && classes.primary,
        className,
        disabled && classes.disabled,
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
