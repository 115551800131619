import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export function isLoggedIn () {
  return !!clientStorage.get(StorageKey.TOKEN);
}

export function saveLoginToken (token:string) {
  clientStorage.save(StorageKey.TOKEN, token);
}

export function saveWalletAddress (address:string) {
  clientStorage.save(StorageKey.WALLET_ADDRESS, address);
}

export function isVerifiedEmail () {
  const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);
  return userVerified && userVerified.emailVerified ? userVerified.emailVerified : false;
}

export function isVerifiedWallet () {
  const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);
  return userVerified && userVerified.walletAssociated ? userVerified.walletAssociated : false;
}

export function isWalletAddress (address:any) {
  let isAddress = false;
  const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);
  if(userVerified && userVerified.address) {
    isAddress = (userVerified.address === address) ? true : false;
  }
  return isAddress;
}

export function logout () {
  clientStorage.clearAll();
}

export function validatePassword (password:string):{ valid:boolean, error:string } {
  let valid = true;
  let error = '';

  // if (password.length < 6) {
  //   valid = false;
  //   error = 'The password must be at least 6 characters long.';
  // }

  // if (!(/[A-Z]/.test(password))) {
  //   valid = false;
  //   error = 'The password must contain at least one capital character.';
  // }

  // if (!(/[0-9]/.test(password))) {
  //   valid = false;
  //   error = 'The password must contain at least one digit.';
  // }

  return { valid, error };
}
