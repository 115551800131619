//@ts-nocheck
import React from "react"
import { makeStyles} from '@material-ui/core/styles';
import Action from './Action';

import { COLOR_WINE, COLOR_TEXT, COLOR_GREY, COLOR_LIGHT_GRAY, COLOR_SIGNUP_BG, FONT_NUNITO} from '../../helpers/styles';
import signupGround from '../../assets/images/signup.jpg';
// import { isLoggedIn } from '../services/user';
import {useHistory} from "react-router-dom";
import SignUpForm from './signUpForm';

const useStyles = makeStyles(theme => ({
  signUpWrapper: {
    backgroundColor: COLOR_SIGNUP_BG,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  left: {
    display: 'flex',
    padding: theme.spacing(5),
    width: '40%',

    '@media (max-width: 1025px)': {
      width: '50%',
    },
    '@media (max-width: 991px)': {
      padding: theme.spacing(0, 3),
      width: '100%',
    }
  },
  right: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1025px)': {
      width: '50%',
    },
  },
  signupImage: {
    borderRadius: '30px',
    width: '100%',
    height: '950px',

    '@media (max-width: 1025px)': {
      width: '450px',
      height: '450px',
    }
  },
}), { name: SignUpContainer.name });


export default function SignUpContainer(props:any) {
  const classes = useStyles();
  const pageSize = props.page;

  return (
    <div className={classes.signUpWrapper}>
      <div className={classes.left}>
        <SignUpForm />
      </div>

      { pageSize == 'lg' ?
        <div className={classes.right}>
          <img className={classes.signupImage} src={signupGround} alt="ground" />
        </div>
      : '' }

    </div>
  );
}
