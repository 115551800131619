//@ts-nocheck
import React from 'react';
import TimelineWrapperLG  from './TimelineWrapper.lg';
import TimelineWrapperSM from './TimelineWrapper.sm';
export const TimelineWrapper = (props: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  
      if(width  <= 959){
          return <TimelineWrapperSM {...props} />
      } else {
        return <TimelineWrapperLG {...props} />
      }
}