import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import web3 from "web3";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MaterialButton from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Modal from "../../Modal/Modal";
import Loader from "../../Loader/Loader";
import Timeline, { CenturyDataType } from "../Timeline";
import { apiGetGasPrices, apiGetAccountNonce } from "../../../helpers/api";
import { sanitizeHex } from "../../../helpers/utilities";
import {
  convertAmountToRawNumber,
  convertStringToHex,
} from "../../../helpers/bignumber";
import gql from "../../../helpers/gql";
import {
  ETHERIUM_API,
  WEI_CURRENCY,
  ETH_CURRENCY,
  MINTER,
  INR_CURRENCY,
  WEI_RATIO,
} from "../../../helpers/constants";
import { NFTStorage } from "../../../helpers/NFTStorage";
import {
  fetchTransactionObject,
  buyTokenAttempt,
  changeProductStatus,
  cancelPurchase,
  registerStatus,
} from "../../../helpers/transaction";
import { weiToEther } from "../../../helpers/helper";
import promiseTimeout from "../../../helpers/timeout-promise";
import { State } from "../../../redux/reducers";
import MintAsset, { Status } from "../../../types/mintasset";
import {
  COLOR_DARK_GRAY,
  COLOR_LIGHT_WINE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_WINE,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_BLUE,
  DISABLED_COLOR
} from "../../../helpers/styles";
import DialogContainer from '../../Dialog/DialogContainer';
import { isLoggedIn, isVerifiedEmail, isVerifiedWallet, logout, isWalletAddress } from '../../../helpers/user';
import * as clientStorage from '../../../helpers/client-storage';
import { StorageKey } from '../../../helpers/client-storage';
interface TimelineWrapperProps {
  wallet: any;
}

const data: CenturyDataType[] = [];

const useStyles = makeStyles(
  (theme) => ({
    timelineWrapper: {
      padding: theme.spacing(0, 2),
      display: "flex",
      flexDirection: "column",
      position: "relative",
      overflow: "hidden",
    },
    pageHeader: {
      color: COLOR_PRIMARY,
      padding: theme.spacing(6, 0),
    },
    headerTitle: {
      marginBottom: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
    },
    subHeaderTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "4px",
    },
    timeline: {
      overflow: "auto",
    },
    timelineContentWrapper: {
      boxShadow: `0 10px 10px 0 ${COLOR_DARK_GRAY}`,
      padding: theme.spacing(3),
      display: "flex",
    },
    timelineContent: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),

      '& .MuiButton-root.Mui-disabled': {
        backgroundColor: DISABLED_COLOR,
        backgroundImage: 'none',
      },
    },
    productImage: {
      //height: 'auto',
      width: "300px",
      height: "400px",
      resizeMode: "contain",
    },
    score: {
      height: "120px",
      width: "120px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "32px",
      color: COLOR_PRIMARY,
      backgroundColor: "rgba(0, 123, 58, 0.2)",
    },
    productName: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      padding: theme.spacing(1.5, 0),
      borderBottom: `1px solid rgba(0,0,0,.1)`,
      marginBottom: theme.spacing(2),
    },
    productDescription: {
      display: "flex",
      fontSize: theme.typography.pxToRem(15),
      padding: theme.spacing(1.5, 0),
      lineHeight: "1.7",
      letterSpacing: ".7px",
      margin: theme.spacing(2, 0),
    },
    properties: {
      minWidth: "200px",
      display: "flex",
      flexDirection: "column",
    },
    key: {},
    value: {
      fontSize: "22px",
      letterSpacing: "2px",
    },
    value2: {
      fontSize: "22px",
      letterSpacing: "2px",
      display:"flex",
      flexDirection:"column"

    },

    assetName: {
      fontSize: theme.typography.pxToRem(28),
      marginLeft: theme.spacing(4),
      fontWeight: 700,
    },
    buyNowButton: {
      width: "100px",
      background: COLOR_SECONDARY,
      color: COLOR_WHITE,
      borderColor: COLOR_PRIMARY,
      backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
      fontWeight: 700,
      fontSize: '14px',
      marginTop: theme.spacing(2),

      "&:hover": {
        backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
        color: COLOR_WHITE,
      },
    },
    price: {
      display: "flex",
      padding: theme.spacing(1.5, 0),
      borderBottom: `1px solid rgba(0,0,0,.1)`,
      borderTop: `1px solid rgba(0,0,0,.1)`,

      "&> span": {
        marginRight: "15px",
      },

      "&> strong": {
        fontWeight: 700,
      },
    },

    descriptionTitleStyle:{
      marginRight:"15px"
    },
    comingSoonText: {
      color: COLOR_TEXT,
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 700,
    },
    cardBanner: {
      display: "flex",
      alignItems: "center"
    },

    descriptionStyle:{
      display:"flex",
    flexDirection:"column",
      padding: theme.spacing(1.5, 0),
      borderBottom: `1px solid rgba(0,0,0,.1)`,
      borderTop: `1px solid rgba(0,0,0,.1)`,
      marginTop:"10px",
      letterSpacing: "2px",
      wordBreak:"break-word",
      "&> span": {
        marginRight: "15px",
      },

      "&> strong": {
        fontWeight: 600,

    }
  }
  }),
  { name: TimelineWrapperLG.name }
);

function TimelineWrapperLG(props: TimelineWrapperProps) {
  const { wallet } = props;

  const classes = useStyles();
  const history = useHistory();
  const { productId, active } = useParams<any>();
  const [timelineData, setTimelineData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isProductSold, setIsProductSold] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fiatAssest, setFiatAssest] = useState<any>();
  const [fiatFlag, setFiatFlag] = useState<any>(false);
  const [fiatSuccess, setFiatSuccess] = useState<boolean>(false);
  const [fiatFailure, setFiatFailure] = useState<boolean>(false);
  const [fiatMessage, setFiatMessage] = useState<any>();
  const [fiatCurrency, setFiatCurrency] = useState<any>();

  useEffect(() => {
    fetchTimelineData();
  }, []);

  useEffect(() => {
    if (!!timelineData.length && !selectedData) {
      if (productId) {
        timelineData.forEach((item: any, index: number) => {
          if (item.tokenid === Number(productId)) {
            setSelectedData(item);
          }
        });
      } else {
        setSelectedData(timelineData[active - 1]);
      }
    }
  }, [productId, timelineData, active]);

  useEffect(() => {
    if (selectedData) {
      const soldItems = JSON.parse(
        localStorage.getItem("soldProductIds") || "[]"
      );
      const soldProductIds = soldItems || [];
      const isSold = soldProductIds.includes(selectedData.tokenid.toString());
      setIsProductSold(isSold);

      // ConvertPriceToFiat(selectedData.price.unit);
    }
  }, [selectedData, showModal, pendingRequest]);

  useEffect(() => {
    if (!showModal) {
      setModalTitle("");
    }
  }, [showModal]);

  function fetchTimelineData() {
    setLoading(true);

    gql(`
      mintassetPercatalog(catalogname:"moment",where:{status:{nin: ["Minted","Created"]}, minter:"${MINTER}"}){
        tokenid
        tokenIndex
        assetname
        category
        locationUrl
        attrributes
        status
        price
        account
        minter
        lastTrxDate
        description
      }
    `)
      .then((data: any) => data.mintassetPercatalog as MintAsset[])
      .then((products: MintAsset[]) => setTimelineData(products.sort((a:any, b:any) => {return ((new Date(a.lastTrxDate)).getTime() - (new Date(b.lastTrxDate)).getTime());})))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function postSafeTransferAsset(asset: any, result: any) {
    console.log(result);

    // setLoading(true);
    // setResult('Your Purchase Succeeded. We have initialted asset transfer to your wallet. Please wait for a while');
    // setResult('Asset signed successfully.');

    const from = asset.account ? asset.account : "";

    gql(`
      mutation {
        blockchainControllerLazyMintNFT(mintWithTransferNftAssetInput:
          {
            to: "${wallet.connector.accounts[0]}"
            from: "${from}"
            userid:"${asset.minter}"
            tokenid: "${asset.tokenid}"
            price:{
              unit: ${asset.price.unit}
              currency: "${asset.price.currency}"
              ratio: ${asset.price.ratio}
            }
            signedTransactionData:"${result}"
          })
        }
    `)
      .then((data: any) => data.blockchainControllerLazyMintNFT as any)
      .then((nftassetControllerTransfer: any) => {
        if (nftassetControllerTransfer.success) {
          //setResult('Asset transfer in progress. Please check purchased assets section after sometime.');
          setResult(
            "Asset transfer initiated. Check my assets section after sometime."
          );
        }
      })
      .catch(console.error);
    // .finally(() => setLoading(false));
  }

  function buyAsset(productToBuy: any) {
    setLoading(true);

    gql(`
      nftAssetWithRelations(id: "${productToBuy.tokenid}"){
        tokenid
        status

      }
    `)
      .then((data: any) => data.nftAssetWithRelations as MintAsset)
      .then((product: MintAsset) => {
        if (product.status === Status.PENDING) {
          toggleModal();
          setModalTitle("Asset status check");
          setResult("Asset alredy processed");
        } else {
          sendTransaction(productToBuy);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  async function sendTransaction(product: any) {
    const { connector } = wallet;
    const { chainId, accounts } = connector;
    const address = accounts[0];

    const web3Instance = new web3(ETHERIUM_API);

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = product.account;

    // value
    const _value = product.price.unit / product.price.ratio;

    const value =
      product.price.currency === WEI_CURRENCY
        ? _value.toString()
        : ethers.utils.parseEther(`${_value}`).toString();

    // data
    // const data = "0x";

    // const data = web3Instance.eth.abi.encodeFunctionCall(
    //   {
    //     "inputs": [
    //       {
    //         "internalType": "uint256",
    //         "name": "tokenIndex",
    //         "type": "uint256"
    //       }
    //     ],
    //     "type": "function",
    //     "payable": true
    //   },[product.tokenIndex]
    // );

    const tx: any = await fetchTransactionObject({
      client_id: "did:ethr:0x7f01bf3a14a95098bc9fe5237d81ae7af8927ec8",
      access_token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NkstUiJ9.eyJpYXQiOjE2Mjc0Nzg5MDAsImV4cCI6MTY0MzI1ODkwMCwiaXNzIjoiZGlkOmV0aHI6MHg3ZjAxYmYzYTE0YTk1MDk4YmM5ZmU1MjM3ZDgxYWU3YWY4OTI3ZWM4In0.S06fdQ2SZIIT4BADaqHC18HzesWjyghZDizGXcBJDOcWvxyanygaREC80ksSxzp6UfLAHMNSQTHZnwRUjRzjcwE",
      input_data: {
        contract_address: "0x9e7a3da79c3d5e4855312fff7aa7f9b74bfef0b5",
        signer_address: `${from}`,
        provider_type: "rinkeby-ethr-did",
        method: "buyToken",
        input_params: [
          {
            name: "tokenId",
            type: "uint256",
            value: `${product.tokenIndex}`,
          },
        ],
        gas_price: "high",
        value: `${value}`,
      },
    });

    if (!tx) {
      setResult(
        "Your wallet doesn't have enough funds to complete the transaction."
      );
      toggleModal();
      setPendingRequest(false);
      return;
    }

    const tokenAttemptSuccess = await buyTokenAttempt(product, address);

    if (tokenAttemptSuccess.success) {
      try {
        // open modal
        toggleModal();

        // toggle pending request indicator
        setPendingRequest(true);

        //save transaction over localStorage
        const localStorageProduct = {
          id: product.tokenid,
          productName: product.assetname,
          price: _value,
          to,
          from,
          image: product.locationUrl,
          category: product.category[0].category,
        };
        // changeProductStatus(product, 'Pending');

        const productStatus = await changeProductStatus(product, "Pending");

        if (productStatus.success) {
          // send transaction
          const result = await promiseTimeout(
            90000,
            connector.sendTransaction(tx)
          ); //web3Instance.eth.signTransaction(tx, d.privateKey) //await webInstance.eth.signTransaction(tx); //

          // format displayed result
          const formattedResult = `You can view Assets Owned by you in the Wallet section.`;

          if (result) {
            //Save ordered product in localStorage
            saveBuyedProductLocally(localStorageProduct);

            const soldItems = JSON.parse(
              localStorage.getItem("soldProductIds") || "[]"
            );
            const soldProductIds = soldItems || [];
            soldProductIds.push(product.tokenid.toString());
            localStorage.setItem(
              "soldProductIds",
              JSON.stringify(soldProductIds)
            );

            setResult(formattedResult);
            setSuccessRequest(true);
            // postSafeTransferAsset(product, result);
            const register = registerStatus(product, result);
            // postSafeTransferAsset(product, result);
          } else {
            const changeStatus = await changeProductStatus(product, "Listed");
            const cancelStatus = await cancelPurchase(product);
            setSuccessRequest(false);
          }
        } else {
          const changeStatus = await changeProductStatus(product, "Listed");
          const cancelStatus = await cancelPurchase(product);
          setSuccessRequest(false);
        }

        // display result
        setPendingRequest(false);
        setSuccessRequest(false);
        // setResult(formattedResult || null);
      } catch (error) {
        console.error(error);

        const changeStatus = await changeProductStatus(product, "Listed");
        const cancelStatus = await cancelPurchase(product);

        setPendingRequest(false);
        setSuccessRequest(false);

        if (error === "timedout") {
          localStorage.clear();
          connector.killSession();
          history.push(`/wallet-connect?redirect=${history.location.pathname}`);
        }

        // setResult(`Your transaction of ${product.name}, worth ETH ${product.price} is successfull!`);
        setResult(null);
      }
    } else {
      setPendingRequest(false);
      setSuccessRequest(false);
      toggleModal();
      setResult(
        tokenAttemptSuccess.message ||
          "Asset is in pending status. Plese try after sometime."
      );
    }
  }

  function toggleModal() {
    setShowModal(!showModal);
  }

  function saveBuyedProductLocally(itemDetails: any) {
    NFTStorage.setItem(itemDetails);
  }

  function fetchUserBuyAsset() {
    setLoading(true);
    setFiatFlag(false);
    setFiatAssest([]);

    gql(`
    mutation{
      nftassetUserControllerBuyAsset(id:"${selectedData.tokenid}"){
        amount
        currency
        nftAssetId
        orderId
        placedOn
        status
        userId
      }
    }
    `)
      .then((data:any) => {
        if(data.nftassetUserControllerBuyAsset != null) {
          setFiatFlag(true);
          setFiatAssest(data.nftassetUserControllerBuyAsset);
          setOpenDialog(true);
        } else {
          if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
            setFiatMessage(data.error[0].extensions.responseBody.error.message);
            toggleModal();
            const checkJwtExpire = data.error[0].extensions.responseBody.error.message;
            if(checkJwtExpire.indexOf('jwt') !== -1 && checkJwtExpire.indexOf('expired') !== -1) {
              logout();
              history.push('/login');
            }
          } else {
            setFiatMessage("Something went wrong");
            toggleModal();
          }
        }
      })
      .catch( error => console.log(error))
      .finally(() => setLoading(false));
  }

  function ConvertPriceToFiat(priceUnit:any) {
    setLoading(true);

    gql(`
    mutation{
      nftassetControllerConvertToFiat(
        convertPriceToFiatCurrencyInput:{
          unit: ${priceUnit},
          ratio: ${WEI_RATIO},
          currency: "${WEI_CURRENCY}"
        }
      ){
        currency
        ratio
        unit
      }
    }
    `)
      .then((data:any) => data.nftassetControllerConvertToFiat as any)
      .then((fiatPrice:any) => setFiatCurrency(fiatPrice))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function triggerBuyNow() {
    if (isLoggedIn() && isVerifiedEmail() && isVerifiedWallet()) {
      buyAssetUsingRazorPay();
    } else if (isLoggedIn() && (!isVerifiedEmail() || !isVerifiedWallet())) {
      history.push(`/verification?redirect=${history.location.pathname}`);
    } else if (!isLoggedIn()) {
      history.push(`/login?redirect=${history.location.pathname}`);
    }
  }

  function buyAssetUsingRazorPay() {
    const walletAddress = clientStorage.get(StorageKey.WALLET_ACCOUNT);
    walletAddress && walletAddress.connected && (isWalletAddress(walletAddress.accounts[0]))
    ? fetchUserBuyAsset()
    : history.push(`/wallet-connect?redirect=${history.location.pathname}&wallet-match=false`)
  }

  function fiatAssetCancleOrder() {
    setLoading(true);
    gql(`
      mutation{
        nftassetUserControllerCancleOrder(
          orderId:"${fiatAssest.orderId}"
        )
      }
    `)
    .then((data:any) => {
      if(data.nftassetUserControllerCancleOrder != null) {
        console.log(data.nftassetUserControllerCancleOrder);
      } else {
        if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
          setFiatMessage(data.error[0].extensions.responseBody.error.message);
          toggleModal();
        } else {
          setFiatMessage("Something went wrong");
          toggleModal();
        }
      }
    })
    .catch( error => console.log(error))
    .finally(() => setLoading(false));
  }

  function closeDialog(statusString:any) {
    if(statusString === 'reject') {
      changeProductStatus(selectedData, "Listed");
      if(fiatAssest && fiatAssest.orderId) {
        fiatAssetCancleOrder();
      }
    }

    setOpenDialog(false);
    setFiatFlag(false);
    setFiatMessage('');
    setFiatAssest([]);
  }

  const successCB = (response:any) => {
    setFiatSuccess(true);
    toggleModal();
    fetchTimelineData();
  };

  const failureCB = (response:any) => {
    setFiatFailure(true);
    toggleModal();
    if(fiatAssest && fiatAssest.orderId) {
      fiatAssetCancleOrder();
    }

    fetchTimelineData();
    window.location.reload();
  };

  const dismissCB = () => {
    if(fiatAssest && fiatAssest.orderId) {
      fiatAssetCancleOrder();
    }
  }

  const score =
    selectedData &&
    selectedData.attrributes &&
    selectedData.attrributes.find(
      (attribute: any) => attribute.key === "Score"
    );
  const century =
    selectedData &&
    selectedData.attrributes &&
    selectedData.attrributes.find(
      (attribute: any) => attribute.key === "Century"
    );
  const country =
    selectedData &&
    selectedData.attrributes &&
    selectedData.attrributes.find(
      (attribute: any) => attribute.key === "Country"
    );
  const edition =
    selectedData &&
    selectedData.attrributes &&
    selectedData.attrributes.find(
      (attribute: any) => attribute.key === "Edition"
    );

  return (
    <div className={classes.timelineWrapper}>
      <Loader inProgress={loading} />
      <div className={classes.pageHeader}>
        <div className={classes.headerTitle}>Test 100's</div>
        <div className={classes.subHeaderTitle}>
          ECB Centuries Moments
        </div>
      </div>
      {timelineData && !timelineData.length && (
        <div className={classes.comingSoonText}>Coming Soon...</div>
      )}
      {timelineData && !!timelineData.length && (
        <>
          <div className={classes.timeline}>
            <Timeline
              active={active}
              productId={productId}
              centuryData={timelineData}
              onSelect={(data: any) => setSelectedData(data)}
              timelineWrapper='lg'
            />
          </div>
          {selectedData && (
            <Paper className={classes.timelineContentWrapper}>
              <img
                className={classes.productImage}
                src={selectedData.locationUrl}
                alt="asset"
              />
              <div className={classes.timelineContent}>
                <div className={classes.cardBanner}>
                  <div className={classes.score}>{score && score.value}</div>
                  <div className={classes.assetName}>
                    {selectedData.assetname}
                  </div>
                </div>
                <div className={classes.descriptionStyle} >
                  <div >

                  <span className={classes.descriptionTitleStyle} >Description:</span>
                  <strong >
                      {selectedData.description}
                      </strong>
                    </div>

                </div>

                <div className={classes.productDescription}>
                  <div className={classes.properties}>
                    <div className={classes.key}>Century #</div>
                    <div className={classes.value}>
                      {century && century.value}
                    </div>
                  </div>
                  <div className={classes.properties}>
                    <div className={classes.key}>Opposite Team</div>
                    <div className={classes.value}>
                      {country && country.value}
                    </div>
                  </div>
                  <div className={classes.properties}>
                    <div className={classes.key}>Date</div>
                    <div className={classes.value}>
                      {edition && edition.value}
                    </div>
                  </div>
                </div>

                { fiatCurrency && fiatCurrency.unit ?
                  <div className={classes.price}><span>Price:</span><strong>{`${INR_CURRENCY} ${fiatCurrency.unit}`}</strong></div>
                  : ''
                }

                {/* <div className={classes.price}>
                  <span>Price:</span>
                  <strong>{`${ETH_CURRENCY} ${weiToEther(
                    selectedData.price.unit
                  )}`}</strong>
                </div>
                {wallet.connector && (
                  <MaterialButton
                    className={classes.buyNowButton}
                    disabled={
                      (selectedData.status === Status.LISTED ? false : selectedData.status === Status.SOLD ? false : true) ||
                      pendingRequest ||
                      successRequest
                    }
                    onClick={() => {
                      selectedData.status === Status.SOLD ?
                      history.push(`/product-details/${selectedData?.tokenid}`)
                      :
                      wallet.connector.connected
                        ? sendTransaction(selectedData)
                        : history.push(
                            `/wallet-connect?redirect=${history.location.pathname}`
                          );
                    }}
                  >
                    {selectedData.status === Status.LISTED
                      ? "Buy Now"
                      : selectedData.status === Status.SOLD ? "View" : selectedData.status}
                  </MaterialButton>
                )} */}
                <MaterialButton
                  className={classes.buyNowButton}
                  disabled={
                    (selectedData.status === Status.LISTED ? false : selectedData.status === Status.SOLD ? false : true) ||
                    fiatFlag || fiatSuccess
                  }
                  onClick={() => {
                    selectedData.status === Status.SOLD ?
                    history.push(`/product-details/${selectedData?.tokenid}`)
                    :
                    triggerBuyNow()
                  }}
                >
                  {selectedData.status === Status.LISTED
                    ? "Buy Now"
                    : selectedData.status === Status.SOLD ? "View" : selectedData.status}
                </MaterialButton>
              </div>
            </Paper>
          )}
        </>
      )}
      <DialogContainer
        openDialog={openDialog}
        closeDialog={closeDialog}
        hideHeaderTable={true}
        fiatAssest={fiatAssest}
        successCB={successCB}
        failureCB={failureCB}
        dismissCB={dismissCB}
      />
      <Modal show={showModal} toggleModal={toggleModal}>
        {pendingRequest ? (
          <div className="modal-container">
            <div className="modal-title">
              {"Complete the purchase in your wallet"}
            </div>
            <div className="data-container">
              <p>
                {
                  "Please approve the transaction in your wallet within 1.5 min. Pls note connection to wallet will be lost if no action is taken on your wallet."
                }
              </p>
            </div>
          </div>
        ) : result ? (
          <div className="modal-container">
            <div className="modal-title">
              {/* {!!modalTitle ? modalTitle : "Your Request Succeeded"} */}
            </div>
            <div className="data-container data-table">{result}</div>
          </div>
        ) : fiatMessage && fiatMessage.length ? (
          <div className="modal-container">
            <div className="data-container">
            <p>{fiatMessage}</p>
            </div>
          </div>
        ) : fiatSuccess ? (
          <div className="modal-container">
            <div className="data-container">
            <p>{"check after sometime in your asset section"}</p>
            </div>
          </div>
        ) : fiatFailure ? (
          <div className="modal-container">
            <div className="data-container">
            <p>{"Due to transaction failure asset transfer failed. Please try again."}</p>
            </div>
          </div>
        ): (
          <div className="modal-container">
            <div className="modal-title">{"Purchase Request Denied"}</div>
          </div>
        )}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: State) => ({
  wallet: state.wallet,
});

export default connect(mapStateToProps)(TimelineWrapperLG);
