import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button
} from '@material-ui/core';
import Loader from '../Loader/Loader';
import Grid from '@material-ui/core/Grid';
import {
  ETH_CURRENCY,
  MINTER,
} from '../../helpers/constants';
import { weiToEther, getTabNameCategory, getTabIndex } from '../../helpers/helper';
import {
  COLOR_LIGHT_GRAY,
  COLOR_SHADOW,
  COLOR_LIGHT_WINE,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_WINE,
  DISABLED_COLOR,
  COLOR_WINE_1,
  COLOR_WINE_2,
} from '../../helpers/styles';
import gql from '../../helpers/gql';
import MintAsset, { Status } from '../../types/mintasset';
import GalleryTabBar, { TABS }  from './GalleryTabBar';

import sarah_taylor from '../../assets/images/ecb/sarah-taylor.png';
import heather_knight from '../../assets/images/ecb/heather-knight.png';
import emily_arlott from '../../assets/images/ecb/emily-arlott.png';
import tammy_beaumont from '../../assets/images/ecb/tammy-beaumont.png';
import katherine_brunt from '../../assets/images/ecb/katherine-brunt.png';
import kate_cross from '../../assets/images/ecb/kate-cross.png';
import freya_davies from '../../assets/images/ecb/freya-davies.png';
import sophia_dunkley from '../../assets/images/ecb/sophia-dunkley.png';
import sophie_ecclestone from '../../assets/images/ecb/sophie-ecclestone.png';
import amy_jones from '../../assets/images/ecb/amy-jones.png';
import natalie_sciver from '../../assets/images/ecb/natalie-sciver.png';
import fran_wilson from '../../assets/images/ecb/fran-wilson.png';
import lauren_winfield_hill from '../../assets/images/ecb/lauren-winfield-hill.png';
import tash_farrant from '../../assets/images/ecb/tash-farrant.png';
import sarah_glenn from '../../assets/images/ecb/sarah-glenn.png';
import jaydev_unadkat from '../../assets/images/jaydev_unadkat.png';
import kartik_tyagi from '../../assets/images/kartik_tyagi.png';
import mahipal_lomror from '../../assets/images/mahipal_lomror.png';
import manan_vohra from '../../assets/images/manan_vohra.png';
import mayank_markande from '../../assets/images/mayank_markande.png';
import rahul_tewatia from '../../assets/images/rahul_tewatia.png';
import shreyas_gopal from '../../assets/images/shreyas_gopal.png';
import yashasvi_jaiswal from '../../assets/images/yashasvi_jaiswal.png';

export const Players:any = [
  {
    id: 'player_1',
    name: 'Sarah Taylor',
    country: 'Wicketkeeper batter',
    imageUrl: sarah_taylor,
  },
  {
    id: 'player_2',
    name: 'Heather Knight',
    country: 'Middle order batter',
    imageUrl: heather_knight,
  },
  {
    id: 'player_3',
    name: 'Emily Arlott',
    country: 'Bowler',
    imageUrl: emily_arlott,
  },
  {
    id: 'player_4',
    name: 'Tammy Beaumont',
    country: 'Opening batter',
    imageUrl: tammy_beaumont,
  },
  {
    id: 'player_5',
    name: 'Katherine Brunt',
    country: 'Bowling allrounder',
    imageUrl: katherine_brunt,
  },
  {
    id: 'player_6',
    name: 'Kate Cross',
    country: 'Bowler',
    imageUrl: kate_cross,
  },
  {
    id: 'player_7',
    name: 'Freya Davies',
    country: 'Bowler',
    imageUrl: freya_davies,
  },
  {
    id: 'player_8',
    name: 'Sophia Dunkley',
    country: 'Batting allrounder',
    imageUrl: sophia_dunkley,
  },
  {
    id: 'player_9',
    name: 'Sophie Ecclestone',
    country: 'Bowler',
    imageUrl: sophie_ecclestone,
  },
  {
    id: 'player_10',
    name: 'Amy Jones',
    country: 'Wicketkeeper batter',
    imageUrl: amy_jones,
  },
  {
    id: 'player_11',
    name: 'Nat Sciver',
    country: 'Allrounder',
    imageUrl: natalie_sciver,
  },
  {
    id: 'player_12',
    name: 'Fran Wilson',
    country: 'Middle order batter',
    imageUrl: fran_wilson,
  },
  {
    id: 'player_13',
    name: 'Lauren Winfield-Hill',
    country: 'Batter',
    imageUrl: lauren_winfield_hill,
  },
  {
    id: 'player_14',
    name: 'Tash Farrant',
    country: 'Bowler',
    imageUrl: tash_farrant,
  },
  {
    id: 'player_15',
    name: 'Sarah Glenn',
    country: 'Bowling allrounder',
    imageUrl: sarah_glenn,
  },
]
const CATEGORY = {
  GOLD: '/menu/gold',
  PLATINUM: '/menu/platinum',
  DIAMOND: '/menu/diamond',
};

export const CATEGORY_TITLE:{[key:string]: string} = {
  [CATEGORY.GOLD]: 'Gold',
  [CATEGORY.DIAMOND]: 'Diamond',
  [CATEGORY.PLATINUM]: 'Platinum',
};

const CATEGORIES:string[] = [
  CATEGORY.DIAMOND,
  CATEGORY.PLATINUM,
  CATEGORY.GOLD,
];

const useStyles = makeStyles(theme => ({
  gallery: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageHeader:{
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },

  listTitle: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(28),
    // fontWeight: 700,
    font:"14px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
  },
  separator: {
    margin: theme.spacing(3, 0),
  },

  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '50vh',
    position: 'relative',
  },
  card: {
    width: '274px',
    overflow: 'visible',
    margin: '0 auto',
    borderRadius: 30,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    boxShadow: `0 0 25px 0 ${COLOR_SHADOW}`,
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: 'normal',
    transition: 'all .3s ease',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',

    '&:hover': {
      transform: 'translateY(-20px)',
      '&:before': {
        opacity: 1
      }
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 2),

    '&:last-child': {
      paddingBottom: '10px',
    },
    paddingTop:'0px',
    paddingInline:"0px",

    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: DISABLED_COLOR,
      backgroundImage: 'none',
    },
  },
  imageContainer: {
    position: 'relative',
    height: '300px',
    resizeMode: 'contain'
  },
  imageClickAble: {
    cursor: 'pointer',
  },
  itemImage: {
    height: '100%',
    width: '100%',
    padding:"0 0 5px 0",
    borderRadius:"20px 20px 0 0",
    resizeMode: "contain"
  },
  playerInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  playerName: {
    color: COLOR_PRIMARY,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
  },
  country: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    margin: theme.spacing(1, 0)
  },
  productImageContainer: {
    position: 'relative',
    height: '300px',
  },
  buyNowButton: {
    width: '100px',
    background: COLOR_SECONDARY,
    color: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
  
    backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
 
   
    fontWeight: 700,
    fontSize: '14px',

    '&:hover': {
      backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
      color: COLOR_WHITE,
    }
  },
  itemName: {
    color: COLOR_PRIMARY,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
  },
  itemPrice: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    margin: theme.spacing(1, 0)
  },
  itemInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },


}), { name: Gallery.name });

export default function Gallery () {
  const classes = useStyles();
  const history = useHistory();
  const {tab} = useParams<any>();
  const categories = CATEGORIES;
  const soldItems = JSON.parse(localStorage.getItem('soldProductIds') || '[]');
  const soldProductIds =  soldItems || [];

  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<number>(getTabIndex(tab));
  const [category, setCategory] = useState<string>(TABS[getTabIndex(tab)]);

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    setProducts([]);
    fetchAssets();
    setCategory(TABS[selectedTab]);
    setSelectedTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    setCategory(TABS[getTabIndex(tab)]);
    setSelectedTab(getTabIndex(tab));
  }, [tab]);

  function fetchAssets() {
    setLoading(true);

    gql(`
      mintassetPercatalog(catalogname:"menu", where:{status:{nin: ["Minted","Created"]}, minter:"${MINTER}"}){
        tokenid
        assetname
        category
        attrributes
        status
        locationUrl
        price
        account
        minter
      }
    `)
      .then((data:any) => data.mintassetPercatalog as MintAsset[])
      .then((products:MintAsset[]) => setProducts(products))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  if (!products && !products.length) {
    return null;
  }

  return (
    <div className={classes.gallery}>
      <div className={classes.pageHeader}>
      <div className={classes.headerTitle}>
          Flashcards
        </div>
        <div className={classes.subHeaderTitle}>
          ECB's Flash cards
        </div>
      </div>
      <GalleryTabBar
        selectedTab={Number(selectedTab)}
        setSelectedTab={(tab:number) => setSelectedTab(tab)}
        totalproducts={products}
      />
      <div className={classes.itemsContainer}>
        <Grid container style={{display: 'flex', flexWrap: "wrap"}}>
        {!!products.length && products
          .filter((product:any) => (product.category && product.category.length) && product.category[0].category === getTabNameCategory(category.toUpperCase()))
          .sort((a:any, b:any) => {return (b.price.unit - a.price.unit);})
          .map((product:any, index:number) => (
            <Grid item lg={3} md={4} sm={6}  xs={12} key={`${product.name}_${index}`}>
            <Card
              className={classes.card}
            >
            <CardContent className={classes.content}>
                  <div className={`${classes.productImageContainer} ${ !(product.status !== Status.LISTED && product.status !== Status.SOLD) ? classes.imageClickAble : ''}` }
                    onClick={() => product.status !== Status.LISTED && product.status !== Status.SOLD ? undefined : history.push(`/product-details/${product.tokenid}`)}>
                    <img
                      src={product.locationUrl}
                      className={classes.itemImage}
                      alt={product.name}
                    />
                  </div>
                  <div className={classes.itemInfo}>
                    <div className={classes.itemName}>{product.assetname}</div>
                    <div className={classes.itemPrice}>{`${ETH_CURRENCY} ${weiToEther(product.price.unit)}`}</div>
                  </div>
                  <Button
                    disabled={(product.status === Status.LISTED ? false : product.status === Status.SOLD ? false : true)}
                    className={classes.buyNowButton}
                    onClick={() => product.status === Status.LISTED ? history.push(`/product-details/${product.tokenid}`) : product.status === Status.SOLD ? history.push(`/product-details/${product.tokenid}`) : undefined}
                  >
                    {product.status === Status.LISTED ? 'Buy Now' : product.status === Status.SOLD ? "View" : product.status}
                  </Button>
                </CardContent>
          </Card>
          </Grid>
        ))}
        </Grid>
        <Loader inProgress={loading} />
      </div>


      <>
        <div className={classes.itemsContainer}>
          {!!products.length && products
            .filter((product:any) => (!product.category || !product.category.length))
            .map((product:any, index:number) => (
            <Card
              className={classes.card}
              key={`${product.assetname}_${index}`}
            >
              <CardContent className={classes.content}>
                <div className={classes.productImageContainer}>
                  <img
                    src={product.locationUrl}
                    className={classes.itemImage}
                    alt={product.name}
                  />
                </div>
                <div className={classes.itemInfo}>
                  <div className={classes.itemName}>{product.assetname}</div>
                  <div className={classes.itemPrice}>{`${ETH_CURRENCY} ${weiToEther(product.price.unit)}`}</div>
                </div>
                <Button
                  disabled={(product.status === Status.LISTED ? false : product.status === Status.SOLD ? false : true)}
                  className={classes.buyNowButton}
                  onClick={() => history.push(`/product-details/${product.tokenid}`)}
                >
                  {product.status === Status.LISTED ? 'Buy Now' : product.status === Status.SOLD ? "View" : product.status}
                </Button>
                {/* {(soldProductIds.includes(product.tokenid.toString())) ? (
                  <Button
                    disabled
                    className={classes.buyNowButton}
                    onClick={undefined}
                  >
                    Sold
                  </Button>
                )
                :
                (
                  <Button
                    className={classes.buyNowButton}
                    onClick={() => history.push(`/gallery/${product.tokenid}`)}
                  >
                    Buy Now
                  </Button>
                )} */}
              </CardContent>
            </Card>
          ))}
        </div>
      </>
    </div>
  )
}