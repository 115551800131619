import logo from "../assets/images/cap.jpg";
import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export function loadScript(src:any) {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

export default async function displayRazorpay(asset:any, successCB:any, failureCB:any, dismissCB:any) {
  const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  const userInfo = clientStorage.get(StorageKey.USER);

  // credential: {
  //   keyId: 'rzp_test_CzuLdmG0xAMyRD',
  //   keySecret: 'wrKWAMHDFGn1nZ7zG7DoeUx2'
  // }

  const options = {
    key: "rzp_test_CzuLdmG0xAMyRD", // Enter the Key ID generated
    amount: asset.amount.toString(),
    currency: asset.currency,
    name: "Razorpay test.",
    description: "Test Transaction",
    image: { logo },
    order_id: asset.orderId,
    handler: async function (response:any){
      successCB(response);
    },
    prefill: {
        name: "Ecb-testuser",
        email: userInfo.email,
        contact: "9999999999",
    },
    notes: {
        address: "Razorpay test Office",
    },
    theme: {
        color: "#61dafb",
    },
    retry: {
      enabled: false,
      max_count: 0,
    },
    "modal": {
      "ondismiss": function(){
        dismissCB();
      }
    }
  };

  const paymentObject =  new (window as any).Razorpay(options);

  paymentObject.on('payment.failed', function (response:any){
    paymentObject.close();
    failureCB(response);
  });

  paymentObject.open();
}