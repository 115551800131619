import { combineReducers } from 'redux';
import walletReducer, { WalletState } from './walletReducer';
import userReducer, { UserState } from './userReducer';

export interface State {
  wallet: WalletState;
  user: UserState;
}

export default combineReducers({
  wallet: walletReducer,
  user: userReducer,
});
