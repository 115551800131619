import { ethers } from 'ethers';

export function weiToEther(weiAmount:string):string {
  try {
    return ethers.utils.formatEther(weiAmount.toString());
  }
  catch {
    return weiAmount.toString();
  }
}

export function etherToWei(ethAmount:string):string {
  let wei = ethers.utils.parseEther(ethAmount);
  return wei.toString();
}

export function etherScanTxLink(txHash:string):string {
  let etherScanLink =  `https://rinkeby.etherscan.io/tx/${txHash}`;
  return etherScanLink;
}

export function getTabIndex(tabName:string):number {
  switch (tabName) {
    case 'diamond':
      return 0;
    case 'platinum':
      return 1;
    case 'gold':
      return 2;
    default:
      return 0;
  }
}

export function getTabNameCategory(tabName:string):string {
  switch (tabName) {
    case 'DIAMOND':
      return '/menu/diamond';
    case 'PLATINUM':
      return '/menu/platinum';
    case 'GOLD':
      return '/menu/gold';
    default:
      return '/menu/diamond';
  }
};