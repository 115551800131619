import React from 'react';
import { ethers } from 'ethers';
import web3 from "web3";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { convertUtf8ToHex } from '@walletconnect/utils';
import Button from '../Button/Button';
import Header from '../Header/Header';
import AccountAssets from '../AccountAssets/AccountAssets';
import Modal from '../Modal/Modal';
import { getQueryParameter } from '../../helpers/route-helper';
import { withRouter } from 'react-router-dom';

import {
  apiGetAccountAssets,
  apiGetGasPrices,
  apiGetAccountNonce,
  apiSubscribePushNotification,
} from '../../helpers/api';
import {
  sanitizeHex,
  verifySignature,
  hashTypedDataMessage,
  hashPersonalMessage,
} from '../../helpers/utilities';
import {
  convertAmountToRawNumber,
  convertStringToHex
} from '../../helpers/bignumber';
import {
  AssetData,
} from '../../helpers/types';
import { eip712 } from '../../helpers/eip712';
import {
  WALLET_CONNECT_BRIDGE,
  STAGING_BRIDGE,
  ETHERIUM_API,
} from '../../helpers/constants';
import { walletConnect, walletDisconnect } from '../../redux/actions/walletAction';
import { State } from '../../redux/reducers';
import { isVerifiedWallet, isWalletAddress, isLoggedIn, isVerifiedEmail } from '../../helpers/user';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';
import gql from '../../helpers/gql';
import ButtonComponent from '../Button/Button';
import { fetchUserVerification } from '../../redux/dispatchers/user';

import './Wallet.css';

interface AppState {
  connector: WalletConnect | null;
  fetching: boolean;
  connected: boolean;
  chainId: number;
  showModal: boolean;
  pendingRequest: boolean;
  subscribed: boolean;
  uri: string;
  accounts: string[];
  address: string;
  result: any | null;
  assets: AssetData[];
  transaction: boolean;
  balance: number;
  isAddressVerified: boolean;
  modalMessage: string;
}

const INITIAL_STATE: AppState = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 1,
  showModal: false,
  pendingRequest: false,
  subscribed: false,
  uri: '',
  accounts: [],
  address: '',
  result: null,
  assets: [],
  transaction: false,
  balance: 0,
  isAddressVerified: true,
  modalMessage: '',
};

class Wallet extends React.Component<any, any> {
  public state = {
    ...INITIAL_STATE,
  };

  componentDidMount() {
    if (!this.state.address && (!this.state.assets || !this.state.assets.length)) {
      this.connectToWalletConnect();
    }
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    const redirectPath = getQueryParameter('redirect');
    if(this.props.wallet.connector && (this.props.wallet.connector.connected === true) && redirectPath && isWalletAddress(this.state.address)) {
      this.props.history.replace(redirectPath);
    }

    if (this.state.connector && !prevProps.wallet.connector) {
      this.props.walletConnect(this.state.connector);
    }

    if((prevState.address !== this.state.address) && this.state.address && this.state.address.length) {
      const walletMatched = getQueryParameter('wallet-match');
      console.log(walletMatched);
      if( isLoggedIn() && isVerifiedEmail() && (!isVerifiedWallet() || !isWalletAddress(this.state.address)) && (walletMatched == '') ){
        console.log("in header wallet for verification ");
        this.WalletAssociate();
      }

      if(isVerifiedWallet() && !isWalletAddress(this.state.address) && (walletMatched === 'false')){
        this.setState({
          isAddressVerified: false,
          modalMessage: 'The wallet you connected is not the same wallet associated with current user. please Disconnect and scan correct wallet'
        });
        this.toggleModal();
      }
    }
  }

  public WalletAssociate  = () => {
    const userInfo = clientStorage.get(StorageKey.USER);
    console.log('userManagementControllerAssociateWallet');
    gql(`
    mutation{
      userManagementControllerAssociateWallet(
        userId:"${userInfo.id}",
        usersAssociateWalletInput:{
          address:"${this.state.address}"
        })
        {
        success
      }
    }
  `)
    .then((data:any) => {
      if(data.userManagementControllerAssociateWallet != null ) {
        if(data.userManagementControllerAssociateWallet.success) {
          this.props.fetchUserVerification();
          this.props.history.push('/home');
        }
      } else {
        if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
          this.setState({ modalMessage: data.error[0].extensions.responseBody.error.message });
        } else {
          this.setState({ modalMessage: 'Something went wrong' });
        }
        this.toggleModal();
      }
    })
    .catch(console.error);
  }

  public connectToWalletConnect = async () => {
    // bridge url
    const bridge = WALLET_CONNECT_BRIDGE;

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    await this.props.walletConnect(this.state.connector);
    await this.setState({ connector });

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    // subscribe to events
    await this.subscribeToEvents();
  };

  public subscribeToPushNotification = async () => {
    const { connector } = this.state;

    if (!connector) {
      return;
    }

    if (connector) {
      this.setState({ fetching: true });

      try {
        const subscribed = await apiSubscribePushNotification(connector.clientId, `${STAGING_BRIDGE}/push`);

        await this.setState({ fetching: false, subscribed });
      } catch (error) {
        console.error(error);
        await this.setState({ fetching: false });
      }

      await this.setState({ fetching: false });
    }
    await this.subscribeToEvents();
  };

  public subscribeToEvents = () => {
    const { connector } = this.state;

    if (!connector) {
      return;
    }

    if (connector) {
      connector.on('session_update', async (error, payload) => {
        console.info(`connector.on('session_update')`);

        if (error) {
          throw error;
        }

        const { chainId, accounts } = payload.params[0];
        this.onSessionUpdate(accounts, chainId);
      });

      connector.on('connect', (error, payload) => {
        console.info(`connector.on('connect')`);

        if (error) {
          throw error;
        }
        const redirectPath = getQueryParameter('redirect');
        this.onConnect(payload).then(()=>{
          if (redirectPath && isVerifiedWallet() && isWalletAddress(this.state.address)) {
            this.props.history.replace(redirectPath);
          }
        });

      });

      connector.on('disconnect', (error, payload) => {
        console.info(`connector.on('disconnect')`);

        if (error) {
          throw error;
        }

        this.onDisconnect();
      });

      if (connector.connected) {
        const { chainId, accounts } = connector;
        const address = accounts[0];
        this.setState({
          connected: true,
          chainId,
          accounts,
          address,
        });
        this.onSessionUpdate(accounts, chainId);
      }
    }

    this.setState({ connector });
  };

  public onConnect = async (payload:any) => {
    const { chainId, accounts } = payload.params[0];

    const address = accounts[0];
    await this.setState({
      connected: true,
      chainId,
      accounts,
      address,
    });
    // this.getAccountAssets();
    this.getAccountBalance();
  };

  public onDisconnect = async () => {
    this.props.walletDisconnect();
    this.resetApp();
  };

  public onSessionUpdate = async (accounts: string[], chainId: number) => {
    const address = accounts[0];
    await this.setState({ chainId, accounts, address });
    // await this.getAccountAssets();
    await this.getAccountBalance();
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, address, assets });
    } catch (error) {
      console.error(error);
      await this.setState({ fetching: false });
    }
  };

  public getAccountBalance = async () => {
    const { address } = this.state;

    this.setState({ fetching: true });
    const web3Instance = new web3(ETHERIUM_API);
    const balance = await web3Instance.eth.getBalance(address);

    await this.setState({ fetching: false, balance });
  };


  public killSession = async () => {
    const { connector } = this.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.setState({ ...INITIAL_STATE });
  };

  public testSendTransaction = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = address;

    // nonce
    const _nonce = await apiGetAccountNonce(address, chainId);
    const nonce = sanitizeHex(convertStringToHex(_nonce));

    // gasPrice
    const gasPrices = await apiGetGasPrices();
    const _gasPrice = gasPrices.slow.price;
    const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)));

    // gasLimit
    const _gasLimit = 25000;
    const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));

    // value
    const _value = 0;
    const value = ethers.utils.parseEther(`${_value}`)._hex;

    // data
    const data = "0x";

    // test transaction
    const tx = {
      from,
      to,
      nonce,
      gasPrice,
      gasLimit,
      value,
      data,
    };

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send transaction
      const result = await connector.sendTransaction(tx);

      // format displayed result
      const formattedResult = {
        method: "eth_sendTransaction",
        txHash: result,
        from: address,
        to: address,
        value: "0 ETH",
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public testSignPersonalMessage = async () => {
    // alert('testSignPersonalMessage')
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    // test message
    const message = "My email is john@doe.com - 1537836206101";

    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message);

    // personal_sign params
    const msgParams = [hexMsg, address];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await connector.signPersonalMessage(msgParams);

      // verify signature
      const hash = hashPersonalMessage(message);
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "personal_sign",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  }

  public testSignTypedData = async () => {
    const { connector, address, chainId } = this.state;

    if (!connector) {
      return;
    }

    const message = JSON.stringify(eip712.example);

    // eth_signTypedData params
    const msgParams = [address, message];

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // sign typed data
      const result = await connector.signTypedData(msgParams);

      // verify signature
      const hash = hashTypedDataMessage(message);
      const valid = await verifySignature(address, result, hash, chainId);

      // format displayed result
      const formattedResult = {
        method: "eth_signTypedData",
        address,
        valid,
        result,
      };

      // display result
      this.setState({
        connector,
        pendingRequest: false,
        result: formattedResult || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ connector, pendingRequest: false, result: null });
    }
  };

  public toggleModal = () => this.setState({ showModal: !this.state.showModal });

  render() {
    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      pendingRequest,
      result,
      balance,
    } = this.state;

    return (
      <>
        <div className="contentWrapper">
          <Header
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.killSession}
          />
          <div className="wallet">
            {!address && !assets.length ? (
              <Button
                buttonText="Connect to WalletConnect"
                onClick={this.connectToWalletConnect}
              />
            ) : (
              <>
                {/* <h3>Actions</h3> */}
                {/* <div className="actions">
                  <Button
                    buttonText="Test Send transaction"
                    onClick={this.testSendTransaction}
                  />
                  <Button
                    buttonText="Show Personal Data"
                    onClick={this.testSignPersonalMessage}
                  />
                  <Button
                    buttonText="Test SignTypedData"
                    onClick={this.testSignTypedData}
                  />
                  <Button
                    buttonText="Subscribe To Push Notification"
                    onClick={this.subscribeToPushNotification}
                  />
                </div> */}
                <h3>Balances</h3>
                {(fetching && !balance) && <div>Loading</div>}
                {(!fetching && balance) && (
                  <AccountAssets
                    chainId={chainId}
                    assets={assets}
                    balance={balance}
                  />
                )}
              </>
            )}
          </div>
          <Modal show={showModal} toggleModal={this.toggleModal}>
            {pendingRequest ? (
              <div className="modal-container">
                <div className="modal-title">{"Pending Call Request"}</div>
                <div className="data-container">
                  <p>{"Approve or reject request using your wallet"}</p>
                </div>
              </div>
            ) : result ? (
              <div className="modal-container">
                <div className="modal-title">{"Call Request Approved"}</div>
                <div className="data-container data-table">
                  {Object.keys(result).map(key => (
                    <div className="data-row" key={key}>
                      <div className="data-key">{key}</div>
                      <div className="data-value">{result[key].toString()}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : this.state.modalMessage && this.state.modalMessage.length ? (
              <div className="modal-container">
                <div className="data-container">
                  <p>{this.state.modalMessage}</p>
                </div>
                { !this.state.isAddressVerified &&
                  <ButtonComponent
                    onClick={this.killSession}
                    buttonText="Disconnect"
                  />
                }
              </div>
            )
            : (
              <div className="modal-container">
                <div className="modal-title">{"Purchase Request Denied"}</div>
              </div>
            )}
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state:State) => ({
  wallet: state.wallet,
});

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      walletConnect,
      walletDisconnect,
      fetchUserVerification,
    },
    dispatch
  )
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Wallet));