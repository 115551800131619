import React from 'react';
import {Page} from './Pages';
import Gallery from '../components/Gallery/Gallery';

export default function GalleryPage () {
  return (
    <Page>
      <Gallery />
    </Page>
  )
}