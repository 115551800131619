import React, { useEffect } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, StepConnector, StepButton } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


import { COLOR_WHITE, COLOR_WINE, COLOR_GREY, COLOR_LIGHT_GRAY,COLOR_PRIMARY ,DISABLED_COLOR} from '../../helpers/styles';
import { NEXT, BACK } from '../../helpers/constants';

export type CenturyDataType = {
  id: number;
  categoryHash: number;
  name: string;
  price: number;
  score: number;
  against: string;
  datePlayed: string;
  category: string;
  result: string;
  imageUrl?: string;
}

interface TimelineProps {
  active?: number;
  centuryData: any;
  productId?: number;
  onSelect: (data:any) => void;
  timelineWrapper: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepperContainer: {
      boxShadow: `0px 5px 15px ${COLOR_GREY}`,
      display: 'flex',
      flexDirection: 'row',
      height: '100px',
      overflow: 'hidden',
      padding: '5px',
      position: 'relative',
      width: '65%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: 'green',
      },
    },
    stepLabel: {
      cursor: 'pointer',
    },
    scrollBtn: {
      width: '36px',
      height: '36px',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      margin: '0',
      padding: theme.spacing(0, 1),
      border: `2px solid ${COLOR_LIGHT_GRAY}`,
      borderRadius: '50%',
      color: COLOR_PRIMARY,
      transform: 'translateY(-50%)',

      '&:hover': {
        borderColor: COLOR_PRIMARY,
      },

      '@media (max-width: 991px)': {
        padding: theme.spacing(0, 0),
      }
    },
    scrollBtnBack: {
      marginRight: theme.spacing(3),

      '@media (max-width: 991px)': {
        marginRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    },
    scrollBtnForward: {
      marginLeft: theme.spacing(3),
      paddingLeft: '4px',

      '@media (max-width: 991px)': {
        marginLeft: theme.spacing(2),
      }
    },
    disabled: {
      cursor: 'no-drop',
      color: COLOR_LIGHT_GRAY,

      '&:hover': {
        borderColor: COLOR_LIGHT_GRAY,
      },
    },
    stepperTest: {
      left: 0,
      padding: '20px 0',
      position: 'absolute',
      transition: 'transform 330ms ease-in-out',

      '& .MuiStep-horizontal': {
        padding: theme.spacing(0, 6),

        '&:first-child': {
          paddingLeft: '30px',
        },

        '@media (max-width: 991px)': {
          padding: theme.spacing(0, 1),

          '&:first-child': {
            paddingLeft: '10px',
          },
        },
      },

      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        fontSize: theme.typography.pxToRem(18),
      },
    }
  }),
);

function getSteps(centuryData:any) {
  const labels:string[] = [];
  for (let i = 1; i <= centuryData.length; i++) {
    labels.push(`#${i}`);
  }
  return labels;
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: COLOR_PRIMARY
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%',

  },
  filledCircle: {
    backgroundColor: COLOR_WHITE,
    border: `1px solid currentColor`,
  },
  outlinedCircle: {
    backgroundColor: COLOR_WHITE,
    border: `1px solid ${COLOR_PRIMARY}`,
  },
  activeCircle: {
    backgroundColor: COLOR_PRIMARY,
    border: `1px solid ${COLOR_WHITE}`,
  },
  completed: {
    color: COLOR_PRIMARY,
    zIndex: 1,
    fontSize: 18,
  },
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 9px)',
    right: 'calc(50% + 9px)',
  },
  active: {
    '& $line': {
      borderColor: COLOR_PRIMARY
    },
  },
  completed: {
    '& $line': {
      borderColor: COLOR_PRIMARY
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);


function QontoStepIcon(props:any) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <div className={clsx(classes.circle, classes.outlinedCircle)} /> : <div className={clsx(classes.circle, classes.filledCircle, active && classes.activeCircle)} />}
    </div>
  );
}

export default function TimeLine(props:TimelineProps) {
  const classes = useStyles();
  const {
    centuryData,
    onSelect,
    productId,
    active = 0,
    timelineWrapper,
  } = props;
  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(Number(active) - 1);
  const [scrollStep, setScrollStep] = React.useState(0);
  const [defaultScroll, setDefaultScroll] = React.useState(6);

  useEffect(() => {
    if (productId) {
      centuryData.forEach((item:any, index:number) => {
        if (item.tokenid === Number(productId)) {
          setActiveStep(index);
        }
      });
    }
  }, [productId]);

  const steps = getSteps(centuryData);
  const maxMoments = timelineWrapper == 'sm' ? 3 : 6;
  const skipArrowButtons = !(maxMoments >= (centuryData.length-1));

  const handleStep = (step:number) => () => {
    setActiveStep(step);
    onSelect(centuryData[step]);
    history.push(`/moments/${step+1}`);
  };

  const handleScroll = (scroll:number, btnName:string) => () => {
    var checkPoints = 0;
    var setValue = 0;
    if(timelineWrapper == 'lg') {
      checkPoints = 730;
      setValue = 6;
    } else if(timelineWrapper == 'sm') {
      checkPoints = 140;
      setValue = 3;
    }

    if(btnName === BACK && defaultScroll >= 0) {
      scroll = scroll - checkPoints;
      setScrollStep(scroll);
      setDefaultScroll(defaultScroll + setValue);
    }

    if(btnName === NEXT && defaultScroll > setValue) {
      scroll = scroll + checkPoints;
      setScrollStep(scroll);
      setDefaultScroll(defaultScroll - setValue);
    }
  }

  return (
    <div className={classes.root}>
      { skipArrowButtons ?
        <div className={clsx(classes.scrollBtn, classes.scrollBtnBack, (scrollStep === 0) && classes.disabled)} onClick={ (scrollStep === 0) ? undefined : handleScroll(scrollStep, NEXT)}>
          <ArrowBackIosIcon />
        </div>
        : ''
      }

      <div className={classes.stepperContainer}>
        <Stepper
          style={{'transform': `translate3d(${scrollStep}px,0px,0px)`}}
          className={classes.stepperTest}
          alternativeLabel
          // nonLinear
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step
                key={label}
                completed={index < activeStep}
                {...stepProps}
              >
                <StepLabel
                  onClick={handleStep(index)}
                  StepIconComponent={QontoStepIcon}
                  className={classes.stepLabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      { skipArrowButtons ?
        <div className={clsx(classes.scrollBtn, classes.scrollBtnForward, (defaultScroll > (centuryData.length-1)) && classes.disabled)} onClick={(defaultScroll > (centuryData.length-1)) ? undefined : handleScroll(scrollStep, BACK)}>
          <ArrowForwardIosIcon />
        </div>
        : ''
      }
    </div>
  );
}