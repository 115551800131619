import {
  WALLET_CONNECT,
  WALLET_DISCONNECT
} from '../actions/walletAction';

export interface WalletState {
  connector: any;
}

export function getWalletInitialState () {
  return INITIAL_STATE;
}

export function getWalletFromLocalStorage() {
  const item = localStorage.getItem('wallet');
  return item ? JSON.parse(item) : null;
}

const INITIAL_STATE:WalletState = {
  connector: getWalletFromLocalStorage(),
};

export default function walletReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case WALLET_CONNECT:
      return {
        ...state,
        connector: action.payload
      };
    case WALLET_DISCONNECT:
      return {
        ...state,
        connector: null
      };
    default:
      return state;
  }
};