import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { State } from '../../redux/reducers';
import {
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_PRIMARY,
  BACKGROUND_GRADIENT,
} from '../../helpers/styles';
import { isLoggedIn, isVerifiedEmail, isVerifiedWallet } from '../../helpers/user';

interface WalletWrapperProps {
  wallet:any;
}

const useStyles = makeStyles(theme => ({
  walletWrapper: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageHeader:{
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },
  productContainer: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(3),
    display: 'flex',
  },
  actions:{
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    height: '45vh',
    maxWidth: '500px',
    paddingTop: theme.spacing(3),

  },
  action: {
    width: '200px',
    height: '100px',
    boxShadow: `0 0 25px 0 ${COLOR_SHADOW}`,
    backgroundImage: BACKGROUND_GRADIENT,
    color: COLOR_WHITE,
    padding: theme.spacing(2),
    fontSize: '20px',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 2),
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    fontWeight: 800,
    marginBottom: theme.spacing(1.5),
  },

  '@media (max-width: 600px)': {
    actions: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    action: {
      marginBottom: theme.spacing(3)
    }
  },
}), { name: WalletWrapper.name });

function WalletWrapper(props:WalletWrapperProps) {
  const classes = useStyles();
  const {
    wallet,
  } = props;

  const history = useHistory();

  return (
    <div className={classes.walletWrapper}>
      <div className={classes.actions}>
        {/* {wallet.connector && wallet.connector.connected && ( */}
        { isLoggedIn() && isVerifiedEmail() && isVerifiedWallet() && (
          <div className={classes.action} onClick={() => history.push('/orders')}>
            <span className={classes.icon}>
              <i className="pe-7s-diamond pe-lg" />
            </span>
            Purchased Assets
          </div>
        )}
        <div className={classes.action} onClick={() => history.push('/wallet-connect')}>
          <span className={classes.icon}>
            <i className="pe-7s-wallet pe-lg" />
          </span>
          Wallet
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state:State) => ({
  wallet: state.wallet,
});

export default connect(
  mapStateToProps,
)(WalletWrapper);