
export const WALLET_CONNECT_BRIDGE = 'https://walletbridge.vlinder.io/';
export const STAGING_BRIDGE = 'https://staging-be-memorabilia.vlinderserver.online';

// export const ETHERIUM_API = 'https://ethereum-api.xyz';
export const ETHERIUM_API = `https://rinkeby.infura.io/v3/84cae3645de14a7494adb5db2b5f4032`;
export const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY || '8a9259a830384055a56a289e4806a1ab';

//84cae3645de14a7494adb5db2b5f4032

export const ETH_RATIO = 1000000000000000000;
export const WEI_RATIO = 1;
export const WEI_CURRENCY = 'WEI';
export const ETH_CURRENCY = 'ETH';
export const INR_CURRENCY = 'INR';
export const MINTER = '60f66b5d3557d22fe103e2d2'

export const GALLERY_TAB_NAME:{[key: number]:string} = {
  0: 'diamond',
  1: 'platinum',
  2: 'gold',
}

export const NEXT = 'Next';
export const BACK = 'Back';