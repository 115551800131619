//@ts-nocheck
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const Website =()=> {
  return (
    <Typography variant="body1" color="textSecondary">
      {'Copyright © '} 
      {new Date().getFullYear()}
      {" "}
    
      <Link color="inherit" href="https://vlinder.io/">
        vlinder
      </Link>{'.'}{' '}
      {'All rights reserved.'}
     
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: "30vh",
    marginBottom: '40px'
    // marginTop:"calc(5%+60px)",
    // height:"vh",
    // bottom:0,
    //  position:"absolute"
  
  },
 
  footer: {
  
    // marginTop:"calc(5% + 60px)",
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  footerText:{
      justifyContent:"center",
      alignItems:"center"
  }
}));

export const FooterSM=() =>{
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          {/* <Typography variant="body1">powered by vlinder</Typography> */}
          <div className={classes.footerText}>

          < Website/>
          </div>
        </Container>
      </footer>
    </div>
  );
}
