import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import Modal from '../../Modal/Modal';
import { CATEGORY_TITLE } from '../../Gallery/Gallery';
import Loader from '../../Loader/Loader';
import { State } from '../../../redux/reducers';
import { apiGetAccountAssets } from '../../../helpers/api';
import {
  ETH_CURRENCY,
  WEI_CURRENCY,
  INR_CURRENCY,
  WEI_RATIO,
} from '../../../helpers/constants';
import { NFTStorage } from '../../../helpers/NFTStorage';
import gql from '../../../helpers/gql';
import {
  fetchTransactionObject,
  buyTokenAttempt,
  changeProductStatus,
  registerStatus,
  cancelPurchase
} from '../../../helpers/transaction';
import { weiToEther, etherScanTxLink } from '../../../helpers/helper';
import promiseTimeout from '../../../helpers/timeout-promise';
import MintAsset, { Status } from '../../../types/mintasset';
import AssetHistory from '../../../types/assetHistory';
import {
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_LIGHT_WINE,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_WINE,
  COLOR_BLUE,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DISABLED_COLOR
} from '../../../helpers/styles';
import moment from 'moment';
import LaunchIcon from '@material-ui/icons/Launch';
import DialogContainer from '../../Dialog/DialogContainer';
import { isLoggedIn, isVerifiedEmail, isVerifiedWallet, logout, isWalletAddress } from '../../../helpers/user';
import * as clientStorage from '../../../helpers/client-storage';
import { StorageKey } from '../../../helpers/client-storage';
interface ProductDetailsProps {
  wallet:any;
}

const useStyles = makeStyles(theme => ({
  productDetailWrapper: {
    minHeight: '100vh',
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(22),
  },
  pageHeader:{
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },
  productContainer: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(3),
    display: 'flex',
  },
  productDetail: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),

    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: DISABLED_COLOR,
      backgroundImage: 'none',
    },
  },
  productImage:{
    // height: '400px',
    // width: '400px',

    height: '350px',
    width: '250px',
    resizeMode:"contain"
  },
  productName: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,
    marginBottom: theme.spacing(2),
  },
  productDescription: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(1.5, 0),
    lineHeight: '1.7',
    letterSpacing: '.7px',
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,
    // marginBottom: theme.spacing(2),
  },
  buyNowButton: {
    width: '100px',
    background: COLOR_SECONDARY,
    color: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    fontWeight: 700,
    fontSize: '14px',
    marginTop: theme.spacing(2),

    '&:hover': {
      backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
      color: COLOR_WHITE,
    }
  },
  price:{
    display: 'flex',
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,

    '&> span': {
      marginRight: '15px',
    },

    '&> strong': {
      fontWeight: 700,
    }
  },
  category: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${COLOR_DARK_GRAY}`,
  },
  data: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    wordBreak:"break-all",
    '&>div': {
      width: '50%',
    }
  },
  assetHistoryContainer: {
    margin: theme.spacing(4, 0),
  },
  historyHeader: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    padding: theme.spacing(2),
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderBottom: 0,
  },
  table: {},
  modal: {},
  txHashLink: {
    alignItems: 'center',
    color: COLOR_BLUE,
    display: 'flex',
    justifyContent: 'center',
  },
  linkLogo: {
    height: '16px',
  },
  valueStyle:{
    fontWeight:600
  }
}), { name: ProductDetailsSM.name });

function ProductDetailsSM(props:ProductDetailsProps) {
  const classes = useStyles();
  const {
    wallet,
  } = props;

  const { productId } = useParams<any>();
  const history = useHistory();

  const [product, setProduct] = useState<any>();
  const [assetHistory, setAssetHistory] = useState<AssetHistory[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const [isProductSold, setIsProductSold] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fiatAssest, setFiatAssest] = useState<any>();
  const [fiatFlag, setFiatFlag] = useState<any>(false);
  const [fiatSuccess, setFiatSuccess] = useState<boolean>(false);
  const [fiatFailure, setFiatFailure] = useState<boolean>(false);
  const [fiatMessage, setFiatMessage] = useState<any>();

  useEffect(() => {
    fetchAssetHistory();
  }, []);

  useEffect(() => {
    if (productId && !product) {
      fetchProductByTokenId();
    }
  }, [productId, product]);

  useEffect(() => {
    if (!showModal) {
      setModalTitle('');
    }
  }, [showModal]);

  useEffect(() => {
    if (product) {
      const soldItems = JSON.parse(localStorage.getItem('soldProductIds') || '[]');
      const soldProductIds =  soldItems || [];
      const isSold = soldProductIds.includes(product.tokenid.toString());
      setIsProductSold(isSold);
    }
  }, [product, showModal, pendingRequest]);

  function fetchProductByTokenId() {
    setLoading(true);

    gql(`
      nftAssetWithRelations(id: "${productId}", currency:"fiat"){
        tokenid
        tokenIndex
        assetname
        category
        attrributes
        status
        locationUrl
        price
        account
        description
        minter
      }
    `)
      .then((data:any) => data.nftAssetWithRelations as MintAsset)
      .then((product:MintAsset) => setProduct(product))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function fetchAssetHistory() {
    setLoading(true);

    gql(`
      nftAssetHistories(id: "${productId}") {
        currOwner
        nftAssetId
        preOwner
        trxDate
        trxHash
      }
    `)
      .then((data:any) => data.nftAssetHistories as AssetHistory[])
      .then((nftAssetHistories:AssetHistory[]) => setAssetHistory(nftAssetHistories))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function postSafeTransferAsset(asset:any, result:any) {
    // setLoading(true);
    // setResult('Asset signed successfully.');
    const from = asset.account ? asset.account : '';
    gql(`
      mutation {
        blockchainControllerLazyMintNFT(mintWithTransferNftAssetInput:
        {
          to: "${wallet.connector.accounts[0]}"
          from: "${from}"
          userid:"${asset.minter}"
          tokenid: "${asset.tokenid}"
          price:{
            unit: ${asset.price.unit}
            currency: "${asset.price.currency}"
            ratio: ${asset.price.ratio}
          }
          signedTransactionData:"${result}"
        })
      }
    `)
      .then((data:any) => data.blockchainControllerLazyMintNFT as any)
      .then((blockchainControllerLazyMintNFT:any) => {
        if (blockchainControllerLazyMintNFT.success) {
          setResult('Asset transfer initiated. Check my assets section after sometime.');
        }
      })
      .catch(console.error)
      // .finally(() => setLoading(false));
  }

  function buyAsset(productToBuy:any) {
    setLoading(true);

    gql(`
      nftAssetWithRelations(id: "${productToBuy.tokenid}"){
        tokenid
        status
      }
    `)
      .then((data:any) => data.nftAssetWithRelations as MintAsset)
      .then((product:MintAsset) => {
        if (product.status === Status.PENDING) {
          toggleModal();
          setModalTitle('Asset status check');
          setResult('Asset alredy processed');
        } else {
          checkSufficientBalance();
          // sendTransaction(productToBuy);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  async function checkSufficientBalance() {
    const { connector } = wallet;
    const { chainId, accounts } = connector;
    const address = accounts[0];
    setLoading(true);
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      // await this.setState({ fetching: false, address, assets });
      console.log(assets);

    } catch (error:any) {
      console.error(error);
      // await this.setState({ fetching: false });
    }
  }

  async function sendTransaction (product:any) {
    const { connector } = wallet;
    const { chainId, accounts } = connector;
    const address = accounts[0];

//    const web3Instance = new web3(ETHERIUM_API);

    if (!connector) {
      return;
    }

    // from
    const from = address;

    // to
    const to = product.account;

    // value
    const _value = product.price.unit;

    const value = product.price.currency === WEI_CURRENCY ? _value.toString() : ethers.utils.parseEther(`${_value}`).toString();

    setPendingRequest(true);

    const tx:any = await fetchTransactionObject({
      "client_id": "did:ethr:0x7f01bf3a14a95098bc9fe5237d81ae7af8927ec8",
      "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NkstUiJ9.eyJpYXQiOjE2Mjc0Nzg5MDAsImV4cCI6MTY0MzI1ODkwMCwiaXNzIjoiZGlkOmV0aHI6MHg3ZjAxYmYzYTE0YTk1MDk4YmM5ZmU1MjM3ZDgxYWU3YWY4OTI3ZWM4In0.S06fdQ2SZIIT4BADaqHC18HzesWjyghZDizGXcBJDOcWvxyanygaREC80ksSxzp6UfLAHMNSQTHZnwRUjRzjcwE",
      "input_data": {
        "contract_address": "0x9e7a3da79c3d5e4855312fff7aa7f9b74bfef0b5",
        "signer_address": `${from}`,
        "provider_type": "rinkeby-ethr-did",
        "method": "buyToken",
        "input_params": [
          {
            "name": "tokenId",
            "type": "uint256",
            "value": `${product.tokenIndex}`
          }
        ],
        "gas_price": "high",
        "value": `${value}`
      }
    });

    if(!tx) {
      setResult('Your wallet doesnot have enough funds to complete the transaction.');
      toggleModal();
      setPendingRequest(false);
      return;
    }

    const tokenAttemptResponse = await buyTokenAttempt(product, address);

    if (tokenAttemptResponse.success) {
      try {
        // open modal
        toggleModal();

        // toggle pending request indicator
        setPendingRequest(true);

        //save transaction over localStorage
        const localStorageProduct = {
          id: product.tokenid,
          productName: product.assetname,
          price: _value,
          to,
          from,
          image: product.locationUrl,
          category: product.category[0].category
        };

        const productStatus = await changeProductStatus(product, 'Pending');

        if(productStatus.success) {
          // send transaction
          const result = await promiseTimeout(90000, connector.sendTransaction(tx));//await connector.sendTransaction(tx); //web3Instance.eth.signTransaction(tx, d.privateKey) //await webInstance.eth.signTransaction(tx); //

          // format displayed result
          const formattedResult = `You can view Assets Owned by you in the Wallet section.`;

          if (result) {
            //Save ordered product in localStorage
            saveBuyedProductLocally(localStorageProduct);

            const soldItems = JSON.parse(localStorage.getItem('soldProductIds') || '[]');
            const soldProductIds =  soldItems || [];
            soldProductIds.push(product.tokenid.toString());
            localStorage.setItem('soldProductIds', JSON.stringify(soldProductIds));

            setResult(formattedResult);
            setSuccessRequest(true);
            // postSafeTransferAsset(product, result);
            const register = registerStatus(product, result);
            // postSafeTransferAsset(product, result);
          } else {
            const changeStatus = await changeProductStatus(product, 'Listed');
            const cancelStatus = await cancelPurchase(product);
            setSuccessRequest(false);
          }
        } else {
          const changeStatus = await changeProductStatus(product, 'Listed');
          const cancelStatus = await cancelPurchase(product);
          setSuccessRequest(false);
        }

        // display result
        setPendingRequest(false);
        setSuccessRequest(false);
        // setResult(formattedResult || null);
      } catch (error) {
        console.error(error);

        const changeStatus = await changeProductStatus(product, 'Listed');
        const cancelStatus = await cancelPurchase(product);

        setPendingRequest(false);
        setSuccessRequest(false);

        if (error === 'timedout') {
          localStorage.clear();
          connector.killSession();
          history.push(`/wallet-connect?redirect=${history.location.pathname}`);
        }
        // setResult(`Your transaction of ${product.name}, worth ETH ${product.price} is successfull!`);
        setResult(null);
      }
    } else {
      setPendingRequest(false);
      setSuccessRequest(false);
      toggleModal();
      setResult(tokenAttemptResponse.message || 'Asset is in pending status. Plese try after sometime.');
    }

    setTimeout(() => {
      setPendingRequest(false);
    }, 4000);
  }

  function toggleModal () {
    setShowModal(!showModal);
    setFiatFlag(false);
  }

  function saveBuyedProductLocally (itemDetails: any) {
    NFTStorage.setItem(itemDetails);
  }

  function fetchUserBuyAsset() {
    setLoading(true);
    setFiatFlag(false);
    setFiatAssest([]);

    gql(`
    mutation{
      nftassetUserControllerBuyAsset(id:"${productId}"){
        amount
        currency
        nftAssetId
        orderId
        placedOn
        status
        userId
      }
    }
    `)
    .then((data:any) => {
      if(data.nftassetUserControllerBuyAsset != null) {
        setFiatFlag(true);
        setFiatAssest(data.nftassetUserControllerBuyAsset);
        setOpenDialog(true);
      } else {
        if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
          setFiatMessage(data.error[0].extensions.responseBody.error.message);
          toggleModal();
          const checkJwtExpire = data.error[0].extensions.responseBody.error.message;
          if(checkJwtExpire.indexOf('jwt') !== -1 && checkJwtExpire.indexOf('expired') !== -1) {
            logout();
            history.push('/login');
          }
        } else {
          setFiatMessage("Something went wrong");
          toggleModal();
        }
      }
    })
    .catch( error => console.log(error))
    .finally(() => setLoading(false));
  }

  function triggerBuyNow() {
    if (isLoggedIn() && isVerifiedEmail() && isVerifiedWallet()) {
      buyAssetUsingRazorPay();
    } else if (isLoggedIn() && (!isVerifiedEmail() || !isVerifiedWallet())) {
      history.push(`/verification?redirect=${history.location.pathname}`);
    } else if (!isLoggedIn()) {
      history.push(`/login?redirect=${history.location.pathname}`);
    }
  }

  function buyAssetUsingRazorPay() {
    const walletAddress = clientStorage.get(StorageKey.WALLET_ACCOUNT);
    walletAddress && walletAddress.connected && (isWalletAddress(walletAddress.accounts[0]))
    ? fetchUserBuyAsset()
    : history.push(`/wallet-connect?redirect=${history.location.pathname}&wallet-match=false`)
  }

  function fiatAssetCancleOrder() {
    setLoading(true);
    gql(`
      mutation{
        nftassetUserControllerCancleOrder(
          orderId:"${fiatAssest.orderId}"
        )
      }
    `)
    .then((data:any) => {
      if(data.nftassetUserControllerCancleOrder != null) {
        console.log(data.nftassetUserControllerCancleOrder);
      } else {
        if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
          setFiatMessage(data.error[0].extensions.responseBody.error.message);
          toggleModal();
        } else {
          setFiatMessage("Something went wrong");
          toggleModal();
        }
      }
    })
    .catch( error => console.log(error))
    .finally(() => setLoading(false));
  }

  function closeDialog(statusString:any) {
    if(statusString === 'reject') {
      changeProductStatus(product, "Listed");
      if(fiatAssest && fiatAssest.orderId) {
        fiatAssetCancleOrder();
      }
    }

    setOpenDialog(false);
    setFiatFlag(false);
    setFiatMessage('');
    setFiatAssest([]);
  }

  const successCB = (response:any) => {
    setFiatSuccess(true);
    toggleModal();
    fetchProductByTokenId();
  };

  const failureCB = (response:any) => {
    setFiatFailure(true);
    toggleModal();

    if(fiatAssest && fiatAssest.orderId) {
      fiatAssetCancleOrder();
    }

    fetchProductByTokenId();
    window.location.reload();
  };

  const dismissCB = () => {
    if(fiatAssest && fiatAssest.orderId) {
      fiatAssetCancleOrder();
    }
  }

  return (
    <div className={classes.productDetailWrapper}>
      <Loader inProgress={loading} />
      <div className={classes.pageHeader}>
        <div className={classes.headerTitle}>
          Flash Card
        </div>
        <div className={classes.subHeaderTitle}>
          ECB's Flash cards
        </div>
      </div>
      {!product && (
        <div className={classes.noData}>
          Asset Details are not available
        </div>
      )}
      {(product) && (
        <Paper className={classes.productContainer}>
           <Grid container >
            <Grid item xs={12}>
          <img
            src={product.locationUrl}
            className={classes.productImage}
            alt='product'
          />
          </Grid>
              <Grid item xs={12} >
          <div className={classes.productDetail}>
          {/* <MaterialButton
              className={classes.buyNowButton}
              disabled={product.status !== Status.LISTED || pendingRequest || successRequest }
              onClick={() => {wallet.connector.connected ? sendTransaction(product) : history.push(`/wallet-connect?redirect=${history.location.pathname}`)}}
            >
              {product.status === Status.LISTED ? 'Buy Now' : product.status}
            </MaterialButton> */}
            <MaterialButton
              className={classes.buyNowButton}
              disabled={product.status !== Status.LISTED || fiatFlag || fiatSuccess }
              onClick={triggerBuyNow}
            >
              {product.status === Status.LISTED ? 'Buy Now' : product.status}
            </MaterialButton>
            <div className={classes.productName}>{product.assetname}</div>
            {/* <div className={classes.price}><span>Price:</span><strong>{`${ETH_CURRENCY} ${weiToEther(product.price.unit)}`}</strong></div> */}
            <div className={classes.price}><span>Price:</span><strong>{`${INR_CURRENCY} ${product.price.unit}`}</strong></div>
            <div className={classes.productDescription}>{product.description}</div>
            <div className={classes.category}>
              <div className={classes.data}><div>Category</div><div  className={classes.valueStyle}>{CATEGORY_TITLE[product.category[0].category]}</div></div>
              <div className={classes.data}><div>Serial No.</div><div  className={classes.valueStyle}>{product.tokenid}</div></div>
            </div>
            <div className={classes.category}>
              {product.attrributes && (
                (product.attrributes.map((data:any, index:number) => (
                  <div className={classes.data} key={index}>
                    <div>{data.key}</div>
                    <div className={classes.valueStyle}>{data.value}</div>
                  </div>
                )))
              )}
            </div>
          </div>
          </Grid>
            </Grid>
        </Paper>
      )}
      <DialogContainer
        openDialog={openDialog}
        closeDialog={closeDialog}
        hideHeaderTable={true}
        fiatAssest={fiatAssest}
        successCB={successCB}
        failureCB={failureCB}
        dismissCB={dismissCB}
      />
      <div className={classes.assetHistoryContainer}>
        <div className={classes.historyHeader}>
          Asset history
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Asset Id</TableCell>
                <TableCell align="center">From</TableCell>
                <TableCell align="center">To</TableCell>
                <TableCell align="center">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetHistory && !!assetHistory.length && (
                assetHistory.map((asset:any) => (
                  <TableRow key={asset.nftAssetId}>
                    <TableCell align="center">{asset.nftAssetId}</TableCell>
                    <TableCell align="center">{asset.preOwner}</TableCell>
                    <TableCell align="center">{asset.currOwner}</TableCell>
                    <TableCell align="center"><a className={classes.txHashLink} target="_blank"  href={etherScanTxLink(asset.trxHash)}>{moment(asset.trxDate).format('DD/MM/YYYY')} <LaunchIcon className={classes.linkLogo}/></a></TableCell>
                  </TableRow>
                ))
              )}
              {assetHistory && !assetHistory.length && (
                <TableRow key='no-data'>
                  <TableCell colSpan={3}>
                    <div className={classes.noData}>No Data Available</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal show={showModal} toggleModal={toggleModal} className={classes.modal}>
          {pendingRequest ? (
            <div className="modal-container">
              <div className="modal-title">{"Complete the purchase in your wallet"}</div>
              <div className="data-container">
                <p>{"Please approve the transaction in your wallet within 1.5 min. Pls note connection to wallet will be lost if no action is taken on your wallet."}</p>
              </div>
            </div>
          ) : result ? (
            <div className="modal-container">
              {/* <div className="modal-title">{"Your Request Succeeded"}</div> */}
              <div className="data-container data-table">
                {result}
              </div>
            </div>
          )  : fiatMessage && fiatMessage.length ? (
            <div className="modal-container">
              <div className="data-container">
              <p>{fiatMessage}</p>
              </div>
            </div>
          ) : fiatSuccess ? (
            <div className="modal-container">
              <div className="data-container">
              <p>{"check after sometime in your asset section"}</p>
              </div>
            </div>
          ) : fiatFailure ? (
            <div className="modal-container">
              <div className="data-container">
              <p>{"Due to transaction failure asset transfer failed. Please try again."}</p>
              </div>
            </div>
          )
          : (
            <div className="modal-container">
              <div className="modal-title">{"Purchase Request Denied"}</div>
            </div>
          )}
        </Modal>
    </div>
  )
}

const mapStateToProps = (state:State) => ({
  wallet: state.wallet,
});

export default connect(
  mapStateToProps,
)(ProductDetailsSM);
