//@ts-nocheck
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button as DefaultButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownOutlined from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowRightOutlined from '@material-ui/icons/ArrowRightOutlined';

import Button from "../Button";
import {
  PAPER_WHITE,
  COLOR_WINE,
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_DARK_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
} from "../../../../helpers/styles";
import { FONT_NUNITO } from "../../../../styles/fonts";

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
  },
  timelineContentWrapper: {
    //boxShadow: `0 10px 10px 0 ${COLOR_DARK_GRAY}`,
    backgroundColor: PAPER_WHITE,
    padding: theme.spacing(2),
    // width: "40%",
    borderRadius: 20,
    minHeight: 400,
    paddingTop: theme.spacing(3),
  },
  paperHeader: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    fontWeight: 700,
  },
  paperSubHeaderline: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(18),
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  submitWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

    "@media (max-width: 991px)": {
      flexDirection: "column",
    },
  },
  submitButton: {
    borderRadius: "5px",
    color: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2, 6),
    width: "auto",
    background: COLOR_SECONDARY,
    color: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    backgroundImage: `linear-gradient(90deg,${COLOR_PRIMARY} 0,${COLOR_SECONDARY} 51%,${COLOR_PRIMARY})`,
    fontWeight: 700,

    '&:hover': {
      borderColor: COLOR_PRIMARY,
      backgroundImage: `linear-gradient(90deg,${COLOR_SECONDARY} 0,${COLOR_PRIMARY} 51%,${COLOR_SECONDARY})`,
      color: COLOR_WHITE,
    }
  },
  walletHeader: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(22),
    textAlign: "center",
    fontWeight: 700,
  },
  walletSubHeader: {
    color: COLOR_DARK_GRAY,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(18),
    textAlign: "center",
    fontWeight: 400,
    padding: theme.spacing(3),
  },
  imgContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  itemImage: {
    height: "40%",
    width: "40%",
  },
  n: {
    olor: COLOR_LIGHT_GRAY,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
  },
  footerButton: {
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2, 6),
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
    width:"max-content"
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  cardStyle: {
    Width: "150px",
    height: "129px",
    margin: "20px",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection:"column",
    padding:"10px"
  },
  media: {
    minWidth: '100%',
    // height: "300px",
    // width:100,
    paddingTop: '56.25%',
    padding:"1px",
    backgroundSize:"contain"
  },
}));

interface VerifyProps {
  walletData?: any;
  onWalletSubmit?(): void;
}

export default function WalletAssociateSM(props: VerifyProps) {
  const classes = useStyles();
  const { walletData, onWalletSubmit } = props;

  const [selectWallet, setSelectWallet] = useState();
  const [wallet, setWallet] = useState({
    logo: "https://i.ibb.co/9q6z48b/image.png",
    href: "https://metamask.io",
    label: "Metamask"
  })

  const _renderItem = () => {
    let res = [];
    walletData &&
      Array.isArray(walletData) &&
      walletData.map((item, index) => {
        console.log(`image:`, item?.img)
        res.push(
          <Grid item xs={6}>
            <Card
              className={classes.cardStyle}
            //   onClick={() => console.log(`clciked:`, item)}
              onClick={() => {
                let _wallet = {...wallet};
                _wallet.logo = item?.logo ? item?.logo : '';
                _wallet.href = item?.href ? item?.href : '';
                _wallet.label = item?.label ? item?.label : '';
                setWallet(_wallet)
              }}
            >
              <CardMedia className={classes.media} image={item?.img} alt=""   />

            </Card>
          </Grid>
        );
      });
    return (
      // <div className={classes.cardWrapper}>
      <Grid container>{res}</Grid>
      // </div>
    );
  };

  return (
    <>
      <div className={classes.paperWrapper}>
        <Paper className={classes.timelineContentWrapper}>
          <div className={classes.walletHeader}>
            You need an wallet to purchase assets on ECB MarketPlace
          </div>
          <div className={classes.walletSubHeader}>Sign in to your wallet using</div>
          <div className={classes.imgContainer}>
            <img
             src={wallet?.logo}
              className={classes.itemImage}
              alt={""}
            />
          </div>
          <div className={classes.submitWrapper}>
            <Button
              primary
              className={classes.submitButton}
              type="submit"
              onClick={onWalletSubmit}
            >
             Continue with {wallet?.label}
            </Button>
          </div>
          <div className={classes.description}>
          Your wallet, powered by <a href={wallet?.href} target='_blank'>{wallet?.label}</a>, lorem ipsum lorem
            ipsum lorem ipsum
          </div>
          <div className={classes.footerButton}>
          <DefaultButton
              default
              onClick={() => {
                setSelectWallet(!selectWallet);
              }}
              startIcon={selectWallet ? <ArrowDropDownOutlined style={{ fontSize: 30 }} /> : <ArrowRightOutlined style={{ fontSize: 30 }} />}
            >
              USE A DIFFERENT WALLET
            </DefaultButton>
          </div>
        </Paper>
      </div>
      {selectWallet && (
        <Grid container style={{justifyContent:"center"}}>
          <Grid item lg={12} md={12}>{_renderItem()}</Grid>
        </Grid>
      )}
    </>
  );
}
