//@ts-nocheck
import React from 'react';

import SideDrawerLG from './SideDrawer';
import SideDrawerSM from './SideDrawer.sm';

export const SideDrawer = (props: any) => {

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

      if(width <= 959){
          return <SideDrawerSM {...props} />
      } else {
        return <SideDrawerLG {...props} />
      }
}