import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { COLOR_WINE,COLOR_PRIMARY } from '../../helpers/styles';

const useStyles = makeStyles(theme => ({
  hamburger : {
    color: COLOR_PRIMARY,
    width: '50px',
    height: '50px',
  },
}), { name: LandingHamburger.name });

export default function LandingHamburger() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    history.push('/signup');
  };

  return (
    <div>
      <Button className={classes.hamburger} onClick={handleClick}>
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Sign In</MenuItem>
        <MenuItem onClick={handleClose}>Sign Up</MenuItem>
      </Menu>
    </div>
  );
}