//@ts-nocheck
import React from "react"
import { makeStyles} from '@material-ui/core/styles';
import Action from './Action';

import { COLOR_WINE, COLOR_TEXT, COLOR_HOME_TEXT, COLOR_WHITE, FONT_NUNITO, COLOR_PRIMARY} from '../../helpers/styles';
import homeImage from '../../assets/images/lara_collage.png';
import { useHistory } from "react-router-dom";
import ExploreIcon from '@material-ui/icons/Explore';

const useStyles = makeStyles(theme => ({
  LandingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '0 auto',
    padding: '25px',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 991px)': {
      alignItems: 'flex-start',
    }
  },
  loginWrapper: {
    width: '50%',
    padding: '97px',
    fontWeight: 600,
    fontFamily: FONT_NUNITO,
    '@media (max-width: 1025px)': {
      padding: '50px',
      width: '100%',
    },
    '@media (max-width: 991px)': {
      padding: '0',
      textAlign: 'center',
    }
  },
  laraHeadingText: {
    fontSize: theme.typography.pxToRem(50),
    color: COLOR_TEXT,
    fontWeight: 700,
    lineHeight: '60px',
    '@media (max-width: 991px)': {
      fontSize: theme.typography.pxToRem(40),
    }
  },
  subheadingText: {
    fontSize: theme.typography.pxToRem(15),
    color: COLOR_HOME_TEXT,
    lineHeight: '25px',
    marginTop: theme.spacing(2),

    '@media (max-width: 991px)': {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: '35px',
    }
  },
  loginImage: {
    // width: "600px",
    height: "550px",
  },
  exploreWrapper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
    }
  },
  exploreButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: '24px',
    color: COLOR_PRIMARY,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    justifyContent: 'center',
    padding: '10px',
    opacity: 1,
    width: '50%',
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      color: COLOR_WHITE,
      textDecoration: 'none'
    },

    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(30),
      marginRight: theme.spacing(1),
    }
  },
}), { name: LandingContent.name });

export default function LandingContent(props:any) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.LandingWrapper}>
      <div className={classes.loginWrapper}>
      { props.pageSize == 'lg' ?
        <>
          <div className={classes.laraHeadingText}>Discover and collect</div>
          <div className={classes.laraHeadingText}>extraordinary NFTs</div>
        </>
        : <div className={classes.laraHeadingText}>ECB MarketPlace</div>
      }
        <div className={classes.subheadingText}>A Collection of Memorable Cricket events of England and Wales Cricket Board (ECB). Browse and Own the unique and rare NFTs of the events</div>
        <div className={classes.exploreWrapper}>
          <Action className={classes.exploreButton} href='/gallery'>
            <ExploreIcon /> Explore
          </Action>
        </div>
      </div>

      { props.pageSize == 'lg' ?
        <div>
          <img className={classes.loginImage} src={homeImage} alt="login-image" />
        </div>
      : '' }
    </div>

  );
}
