import React from 'react';
import {Page} from './Pages';
import {ProductDetails} from '../components/ProductDetails/ProductDetailsPage';

export default function ProductDetailsPage () {
  return (
    <Page>
      <ProductDetails />
     
    </Page>
  )
}