import React from 'react';
import {FormEvent, useState, useEffect} from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../redux/reducers';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Loader from '../Loader/Loader';
import Input from '../Input/Input';
import LoginButton from './LoginButton';
import {
  COLOR_WHITE,
  COLOR_LOGIN_BTN,
  COLOR_LOGIN_BG,
  COLOR_TEXT,
  FONT_NUNITO
} from '../../helpers/styles';
import {
  getEmailData,
  getPasswordData,
} from '../../helpers/validation';
import gql from '../../helpers/gql';
import { saveLoginToken, logout, isLoggedIn, isVerifiedEmail, isVerifiedWallet } from '../../helpers/user';
import { getQueryParameter } from '../../helpers/route-helper';
import { fetchUser, fetchUserSettings, fetchUserVerification } from '../../redux/dispatchers/user';
import Modal from '../Modal/Modal';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
  loginConatiner: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    padding: '50px 0px',
    width: '45%',

    '& .MuiFormHelperText-root.Mui-error': {
      color: COLOR_WHITE
    },

    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ': {
      borderColor: COLOR_WHITE
    },

    '@media (max-width: 767px)': {
      width: '85%',
    }
  },
  loginForm: {
    backgroundColor: COLOR_LOGIN_BG,
    backdropFilter:"blur(20px)",
    borderColor: COLOR_WHITE,
    borderRadius: '22px',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px',
    width: '100%',

    '@media (max-width: 1025px)': {
      padding: '30px',
    },
    '@media (max-width: 991px)': {
      padding: '10px',
    }
  },
  title: {
    fontSize: theme.typography.pxToRem(28),
    margin: 0,
    color: COLOR_WHITE,
  },
  input: {
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  submitButton: {
    backgroundColor: 'transparent',
    borderColor: COLOR_LOGIN_BTN,
    borderRadius: '25px',
    color: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(24),
    margin: '0 auto',
    padding: '10px 32px',
    opacity: 1,
    width: '70%',

    '&:hover': {
      borderColor: COLOR_LOGIN_BTN,
      backgroundColor: COLOR_LOGIN_BTN,
      color: COLOR_TEXT,
    }
  },

  '@media (max-width: 400px)': {
    submitButton: {
      padding: theme.spacing(2, 3),
    },
    forgotPassword: {
      padding: 0,
    },
  },
  signupLink: {
    color: COLOR_WHITE,
    fontWeight: 600,
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    color: COLOR_WHITE,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  homeIcon: {
    cursor: 'pointer',
    height: '30px',
    position: 'absolute',
    right: '35px',
    top: '15px',
    width: '20px',

    '& .MuiSvgIcon-root': {
      color: COLOR_WHITE,
      fontSize: theme.typography.pxToRem(40),
    }
  },
}), { name: LoginForm.name });

export function LoginForm (props:any) {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  function toggleModal() {
    setShowModal(!showModal);
    setIsLoading(false);
  }

  useEffect(() => {
    if(isLoggedIn() && isVerifiedEmail() && isVerifiedWallet() ){
      history.replace('/home');
    }
  },[])

  useEffect(() => {
    const user = props.user;
    const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);
    if(userVerified != null) {
      const redirectPath = getQueryParameter('redirect');
      if (user.userVerification && user.userVerification.emailVerified && user.userVerification.walletAssociated) {
        history.push(
          (redirectPath && isVerifiedEmail() && isVerifiedWallet())
          ? redirectPath
        : '/verification');
        setIsLoading(false);
      } else if (user.userVerification && (!user.userVerification.emailVerified || !user.userVerification.walletAssociated) ) {
        history.push('/verification');
        setIsLoading(false);
      }
    }
  }, [props.user]);

  function onLogIn (event:FormEvent) {
    event.preventDefault();

    if (checkFieldsAreValid(email, password)) {
      logout();
      setIsLoading(true);

      gql(`
      mutation{
        userManagementControllerLogin(
          usersLoginInput:{
            email: "${email}",
            password:"${password}"
          }
        )
        {
          token
        }
      }
    `)
      .then((data:any) => {
        if(data.userManagementControllerLogin != null) {
          saveLoginToken(data.userManagementControllerLogin.token);
          props.fetchUser();
          props.fetchUserVerification();
        } else {
          if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
            setModalMessage(data.error[0].extensions.responseBody.error.message);
          } else {
            setModalMessage("Something went wrong");
          }
          toggleModal();
        }
      })
      .catch(console.error)
      // .finally(() => setIsLoading(false));
    }
  }

  function checkFieldsAreValid (email:string, password:string) {
    // let { valid, error } = validatePassword(password);
    let valid = validateEmail(email);
    valid = validatePassword(password);
    return valid;
  }

  function validateEmail(email:string) {
    const emailData = getEmailData(email);
    setEmailError(emailData.errorMsg);
    return emailData.valid;
  }

  function validatePassword(password:string) {
    const pswdData = getPasswordData(password);
    setPasswordError(pswdData.errorMsg);
    return pswdData.valid;
  }

  function goHomePage() {
    history.push('/home');
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  return (
    <div className={classes.loginConatiner}>
      <form
        className={classes.loginForm}
        onSubmit={onLogIn}
      >
        <div className={classes.homeIcon} onClick={goHomePage}>
          <HomeIcon/>
        </div>
        <Loader inProgress={isLoading} />

        { query.get("signup") && query.get("signup") == 'true' &&
          <Alert severity="success">Account has been created successfully. Please Login to continue.</Alert>
        }

        <br/>

        <div className={classes.title}>LOGIN</div>

        <Input
          className={classes.input}
          placeholder='Email'
          value={email}
          error={emailError}
          onChange={(value:string) => setEmail(value.trim())}
          onBlur={(value:string) => validateEmail(value)}
          textColor={COLOR_TEXT}
        />

        <Input
          className={classes.input}
          type='password'
          placeholder='Password'
          value={password}
          error={passwordError}
          onChange={(value:string) => setPassword(value.trim())}
          onBlur={(value:string) => validatePassword(value)}
          textColor={COLOR_TEXT}
        />

        <div className={classes.submitWrapper}>
          <LoginButton
            className={classes.submitButton}
            type='submit'
            primary
            disabled={!!emailError || (password.length < 8) }
          >
            Login
          </LoginButton>
        </div>

        <div className={classes.signupLink}>
          Don't have an account? Please&nbsp;
          <Link
            className={classes.link}
            to='/signup'
          >
            Sign Up
          </Link>
        </div>
      </form>

      <Modal show={showModal} toggleModal={toggleModal}>
        { modalMessage && modalMessage.length && (
            <div className="modal-container">
              <div className="data-container">
                <p>{modalMessage}</p>
              </div>
            </div>
          )
        }
      </Modal>
    </div>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchUser,
      fetchUserSettings,
      fetchUserVerification,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
