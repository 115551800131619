import axios, { AxiosInstance } from 'axios';
import {
  AssetData,
  GasPrices,
  ParsedTx,
  WallectInfo,
} from './types';
import {
  STAGING_BRIDGE,
  ETHERIUM_API,
} from './constants';

const api: AxiosInstance = axios.create({
  baseURL: ETHERIUM_API,
  timeout: 30000, // 30 secs
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

const vlinderApi: AxiosInstance = axios.create({
  baseURL: STAGING_BRIDGE,
  timeout: 30000, // 30 secs
  headers: {
    'Content-Type': 'application/json',
  }
});

export async function apiGetAccountBalance(address: string, chainId: number): Promise<AssetData[]> {
  const response = await api.get(`/account-assets?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export async function apiGetAccountAssets(address: string, chainId: number): Promise<AssetData[]> {
  const response = await api.get(`/account-assets?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number,
): Promise<ParsedTx[]> {
  const response = await api.get(`/account-transactions?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export const apiGetAccountNonce = async (address: string, chainId: number): Promise<string> => {
  const response = await api.get(`/account-nonce?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
};

export const apiGetGasPrices = async (): Promise<GasPrices> => {
  const response = await api.get(`/gas-prices`);
  const { result } = response.data;
  return result;
};

export const apiGetWalletInfo = async (): Promise<WallectInfo> => {
  const response = await vlinderApi.get('/info');

  const result = response.data;
  return result;
};

export const apiSubscribePushNotification = async (clientId:string , webhookUrl:string): Promise<boolean> => {
  const response = await vlinderApi.post(`/subscribe`, {
    "topic": clientId,
    "webhook": webhookUrl
  });

  const result = response.data.success;
  return result;
};
