import React from 'react';
import {Page} from './Pages';
import {Verification} from '../components/Verification/Verification';;

export default function VerificationPage () {
  return (
    <Page>
      <Verification />
    </Page>
  )
}