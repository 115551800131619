import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';
import { COLOR_TEXT, COLOR_WINE,COLOR_PRIMARY } from '../../helpers/styles';
import { GALLERY_TAB_NAME } from '../../helpers/constants';
import { getTabNameCategory } from '../../helpers/helper';

export const TabType:{[key:string]: string} = {
  diamond: 'Diamond',
  platinum: 'Platinum',
  gold: 'Gold',
};

export const TABS = [
  TabType.diamond,
  TabType.platinum,
  TabType.gold,

];
interface GalleryTabBarProps {
  className?: string,
  selectedTab: number,
  setSelectedTab: (value:number) => void;
  totalproducts: any
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '20px',
  },
  tabs: {
    color: COLOR_TEXT,
  },
  tab: {
    //fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  selected: {
    borderBottom: `3px solid ${COLOR_PRIMARY}`
  }
}), { name: GalleryTabBar.name });

export default function GalleryTabBar(props:GalleryTabBarProps) {
  const classes = useStyles();
  const history = useHistory();
  const {
    className,
    selectedTab,
    setSelectedTab,
    totalproducts,
  } = props;
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const handleChange = (event:any, newValue:number) => {
    setSelectedTab(newValue);
    const tabName = GALLERY_TAB_NAME[newValue];
    history.push(`/gallery/${tabName}`);
  };

  const _tabStyle = () => {
    const _style = {
      fontSize: width <= 959 ? 14 : 18
    }
    return _style;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Tabs
        className={classes.tabs}
        classes={{
          indicator: classes.selected
        }}
        value={Number(selectedTab)}
        onChange={handleChange}
        centered
      >
        {TABS.map((tab:string) => (
          <Tab key={tab}
            className={classes.tab}
            style={_tabStyle()}
            label={`${tab} ${totalproducts && totalproducts.length ? `(${(totalproducts.filter((totalproducts:any) => totalproducts.category[0].category ===  getTabNameCategory(tab.toUpperCase()) )).length})` : '' }` }
          />
        ))}
      </Tabs>
    </div>
  );
}