//@ts-nocheck
import React from 'react';

import EmailVerifyLG from './EmailVerify.lg';
import EmailVerifySM from './EmailVerify.sm';

export const EmailVerify = (props: any) => {

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

      if(width <= 959){
          return <EmailVerifySM {...props} />
      } else {
        return <EmailVerifyLG {...props} />
      }
}