export const WALLET_CONNECT = 'WALLET_CONNECT';
export const WALLET_DISCONNECT = 'WALLET_DISCONNECT';

export function walletConnect(wallet:any) {
  if (wallet) {
    localStorage.setItem('wallet', JSON.stringify(wallet));
  }
  return {
    type: WALLET_CONNECT,
    payload: wallet
  }
}

export function walletDisconnect() {
  localStorage.removeItem('wallet');
  return {
    type: WALLET_DISCONNECT,
    payload: null
  }
}