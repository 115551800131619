import {FormEvent, useState} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';
import Loader from '../Loader/Loader';
import Input from '../Input/Input';
import SignupButton from './SignupButton';
import ecbLogo from '../../assets/images/ecbLogo.png';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {
  COLOR_WHITE,
  COLOR_WINE,
  COLOR_TEXT,
  COLOR_PRIMARY,
  FONT_NUNITO,
  COLOR_BLUE,
  ERROR_COLOR
} from '../../helpers/styles';
import {
  getEmailData,
  getPasswordData,
  getNameData,
} from '../../helpers/validation';
import gql from '../../helpers/gql';
import Modal from '../Modal/Modal';
import { logout } from '../../helpers/user';

const useStyles = makeStyles(theme => ({
  signupContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    padding: theme.spacing(4, 0),
    width: '75%',

    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ': {
      borderColor: COLOR_WHITE
    },

    '@media (max-width: 1025px)': {
      width: '100%',
    }
  },
  helmetLogo: {
    width: '40px',
    height:'40px',
  },
  SignupForm: {
    backdropFilter:"blur(20px)",
    background:"linear-gradient(109.13deg, rgba(239, 239, 239, 0.03) 3.12%, rgba(239, 239, 239, 0.1) 89.96%, rgba(239, 239, 239, 0.174015) 89.96%);",
    borderColor: COLOR_WHITE,
    borderRadius: '0 21px 21px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 1025px)': {
      padding: '30px',
    },
    '@media (max-width: 991px)': {
      padding: '10px',
    }
  },
  input: {
    marginTop: theme.spacing(1),

    '& .MuiOutlinedInput-adornedEnd': {
      backgroundColor: COLOR_WHITE,
    }
  },
  submitWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submitButton: {
    backgroundColor: 'transparent',
    borderColor: COLOR_PRIMARY,
    borderRadius: '25px',
    color: COLOR_TEXT,
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(22),
    margin: '0 auto',
    padding: '16px 32px',
    opacity: 1,
    width: '100%',

    '&:hover': {
      borderColor: COLOR_PRIMARY,
      backgroundColor: COLOR_PRIMARY,
      color: COLOR_WHITE,
    }
  },

  '@media (max-height: 800px)': {
    footer: {
      position: 'relative',
      bottom: 'auto',
      marginTop: theme.spacing(8),
    },
  },

  '@media (max-width: 400px)': {
    submitButton: {
      padding: theme.spacing(2, 3),
    },
    forgotPassword: {
      padding: 0,
    },
  },
  signupHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  verifyLabel: {
    alignItems: 'flex-start',
    display: 'flex',
    marginRight: 0,
  },
  checkbox: {
    color: COLOR_PRIMARY,
    padding: '2px 9px',

    '& $checkIconWrapper': {
      background: COLOR_PRIMARY,
      borderColor: COLOR_PRIMARY,
    }
  },
  verifyWrapper: {
    fontSize: theme.typography.pxToRem(17),
  },
  verifyLink: {
    color: COLOR_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  loginLink: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helmentText: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    marginTop: '5px',
  },
  checkboxError: {
    color: ERROR_COLOR,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '3px',
    textAlign: 'left',
  },
}), { name: SignUpForm.name });

export default function SignUpForm () {
  const classes = useStyles();
  const history = useHistory();

  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [verifyPolicy, setVerifyPolicy] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  function toggleModal() {
    setShowModal(!showModal);
  }

  function onSignUp (event:FormEvent) {
    event.preventDefault();
    logout();
    let validFields = true;

    const isFirstNameValid = validateFirstName(firstName);
    const isLastNameValid = validateLastName(lastName);
    const isEmailValid = validateEmail(email);
    const isPswdValid = validatePassword(password);
    const isConfrmPswd = validateConfirmPassword(confirmPassword);

    if(!isFirstNameValid || !isLastNameValid || !isEmailValid || !isPswdValid || !isConfrmPswd) {
      validFields = false;
    }

    if(validFields && verifyPolicy) {
      setIsLoading(true);

      gql(`
        mutation createuser{
          userManagementControllerSignUp(newUserInput:{
            email: "${email}"
            firstName: "${firstName}"
            lastName: "${lastName}"
            password: "${password}"
            managed: true
            onestepmint: false
          }){
            id,
            email,
            emailVerified,
            firstName,
            lastName,
            roles
          }
        }
      `)
      .then((data:any) => {
        if(data.userManagementControllerSignUp != null) {
          history.push('/login?signup=true');
        } else {
          if(data.error[0] && data.error[0].extensions && data.error[0].extensions.responseBody) {
            setModalMessage(data.error[0].extensions.responseBody.error.message);
          } else {
            setModalMessage("Something went wrong");
          }
          toggleModal();
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
    }
  }

  function handleCheckboxChange(event:any, key:string) {
    switch (key) {
      case 'verifyPolicy':
        setVerifyPolicy(event.target.checked);
        break;

      default:
        break;
    }
  };

  function validateEmail(email:string) {
    const emailData = getEmailData(email);
    setEmailError(emailData.errorMsg);
    return emailData.valid;
  }

  function validatePassword(password:string) {
    const pswdData = getPasswordData(password);
    setPasswordError(pswdData.errorMsg);
    return pswdData.valid;
  }

  function validateFirstName(firstName:string) {
    const fNameData = getNameData(firstName, 'First');
    setFirstNameError(fNameData.errorMsg);
    return fNameData.valid;
  }

  function validateLastName(lastName:string) {
    const lNameData = getNameData(lastName, 'Last');
    setLastNameError(lNameData.errorMsg);
    return lNameData.valid;
  }

  function validateConfirmPassword(confirmPswd:string) {
    let valid = true;

    if (confirmPswd.length === 0) {
      setConfirmPasswordError('Please enter Password');
      valid = false;
    } else if (confirmPswd !== password) {
      setConfirmPasswordError('Password not matched');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }
    return valid;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  return (
    <div className={classes.signupContainer}>
      <form
        className={classes.SignupForm}
        onSubmit={onSignUp}
      >
        <Loader inProgress={isLoading} />
        <div className={classes.signupHead}>
          <img className={classes.helmetLogo} src={ecbLogo} alt='Ecb_img'/>
          <div className={classes.helmentText}> Sign Up</div>
        </div>

        <div className={classes.inputContainer}>
          <FormLabel required>First Name</FormLabel>
          <Input
            className={classes.input}
            placeholder='First Name'
            value={firstName}
            error={firstNameError}
            onChange={(value:string) => setFirstName(value.trim())}
            onBlur={(value:string) => validateFirstName(value)}
            textColor={COLOR_TEXT}
          />
        </div>

        <div className={classes.inputContainer}>
          <FormLabel required>Last Name</FormLabel>
          <Input
            className={classes.input}
            placeholder='Last Name'
            value={lastName}
            error={lastNameError}
            onChange={(value:string) => setLastName(value.trim())}
            onBlur={(value:string) => validateLastName(value)}
            textColor={COLOR_TEXT}
          />
        </div>

        <div className={classes.inputContainer}>
          <FormLabel required>Email Address</FormLabel>
          <Input
            className={classes.input}
            placeholder='Email'
            value={email}
            error={emailError}
            onChange={(value:string) => setEmail(value.trim())}
            onBlur={(value:string) => validateEmail(value)}
            textColor={COLOR_TEXT}
          />
        </div>

        <div className={classes.inputContainer}>
          <FormLabel required>Password</FormLabel>
          <Input
            className={classes.input}
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            value={password}
            error={passwordError}
            onChange={(value:string) => setPassword(value.trim())}
            onBlur={(value:string) => validatePassword(value)}
            textColor={COLOR_TEXT}
            endAdornment={{
              label: <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton> ,
              position: 'end'}}
          />
        </div>

        <div className={classes.inputContainer}>
          <FormLabel required> Confirm Password</FormLabel>
          <Input
            className={classes.input}
            type='password'
            placeholder='Password'
            value={confirmPassword}
            error={confirmPasswordError}
            onChange={(value:string) => setConfirmPassword(value.trim())}
            onBlur={(value:string) => validateConfirmPassword(value)}
            textColor={COLOR_TEXT}
          />
        </div>

        <div className={classes.inputContainer}>
          <FormLabel>
            <FormControlLabel
              className={classes.verifyLabel}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={verifyPolicy}
                  onChange={(event:any) => handleCheckboxChange(event, 'verifyPolicy')}
                  name='verifyPolicy'
                  color='primary'
                />
              }
              label={<div className={classes.verifyWrapper}>By creating an account you agree to the <a className={classes.verifyLink}>terms of use</a> and our <a className={classes.verifyLink}>privacy policy</a></div>}
            />
          </FormLabel>
          { !verifyPolicy ?
              <div className={classes.checkboxError}>Please check the policy</div>
            : '' }
        </div>

        <div className={classes.submitWrapper}>
          <SignupButton
            className={classes.submitButton}
            type='submit'
            primary
            disabled={!!emailError || !!passwordError}
          >
            Create Account
          </SignupButton>
        </div>

        <div className={classes.loginLink}>
          Already have an account?&nbsp;&nbsp;
          <Link
            className={classes.verifyLink}
            to='/login'
          >
            Log in
          </Link>
        </div>

        <div className={classes.loginLink}>
          Want to visit&nbsp;&nbsp;
          <Link
            className={classes.verifyLink}
            to='/home'
          >
            Home
          </Link>
        </div>
      </form>

      <Modal show={showModal} toggleModal={toggleModal}>
        { modalMessage && modalMessage.length && (
            <div className="modal-container">
              <div className="data-container">
                <p>{modalMessage}</p>
              </div>
            </div>
          )
        }
      </Modal>
    </div>
  )
}
