// ProductDetails
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader/Loader';
import TransactionTable from './TransactionTable';
import gql from '../../helpers/gql';
import { State } from '../../redux/reducers';
import {
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_PRIMARY,
  COLOR_WINE,
  COLOR_TEXT
} from '../../helpers/styles';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';

interface TransactionHistoryProps {
  wallet:any;
}

const useStyles = makeStyles(theme => ({
  transactionHistoryWrapper: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageHeader: {
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },
  paper: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(3),
    display: 'flex',
  },
  listTitle: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
  },
  separator: {
    margin: theme.spacing(3, 0),
  },
  table: {
    minWidth: 650,
  },
}), { name: TransactionHistory.name });


function TransactionHistory(props:TransactionHistoryProps) {
  const classes = useStyles();
  const { wallet } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [myAssets, setMyAssets] = useState<any>([]);

  useEffect(() => {
    fetchMyAsets();
  }, []);

  async function fetchMyAsets() {
    setLoading(true);
    const userVerified = clientStorage.get(StorageKey.USER_VERIFIED);
    const accountAddress = userVerified && userVerified.address && userVerified.address.length
        ? userVerified.address : wallet.connector.accounts[0];

    // mintasset(filter:{where:{account:"${wallet.connector.accounts[0]}"}}){
    //mintasset(filter:{where:{account:"0xDa6f68298485cbe650cbEEeb9AFfe415E1164AcE"}}){
    gql(`
      mintassetWithpending(account:"${accountAddress}"){
        tokenid
        tokenIndex
        locationUrl
        status
        assetname
        lastTrxDate
      }
    `)
      .then((data:any) => data.mintassetWithpending as any)
      .then((assets:any) => setMyAssets(assets))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  return (
    <div className={classes.transactionHistoryWrapper}>
      <Loader inProgress={loading} />
      <div className={classes.pageHeader}>
        <div className={classes.headerTitle}>
          Transactions
        </div>
        <div className={classes.subHeaderTitle}>

        </div>

      </div>

      <div className={classes.listTitle}>Assets Owned</div>
      <Divider variant='fullWidth' light className={classes.separator} />
      <TransactionTable myAssets={myAssets} />
    </div>
  )
}

const mapStateToProps = (state:State) => ({
  wallet: state.wallet,
});

export default connect(
  mapStateToProps,
)(TransactionHistory);