import { Dispatch,  } from 'redux';
import { setUser, setUserSettings, setUserVerification } from '../actions/userAction';
import gql from '../../helpers/gql';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';
import { User, UserSettings, UserVerification } from '../../types/User';

export function fetchUser () {
  return (dispatch:Dispatch, getState:any) => {
    return gql(`
      usersMe{
        id
        email
        name
      }
    `)
      .then((data:any) => data && data.usersMe as User)
      .then((user:User) => {
        clientStorage.save(StorageKey.USER, user);
        dispatch(setUser(user));
      })
      .catch(console.error);
  };
}

export function fetchUserSettings () {
  const userInfo = clientStorage.get(StorageKey.USER);
  return (dispatch:Dispatch, getState:any) => {
    return gql(`
      userSettings(id:"${userInfo.id}"){
        address
      }
    `)
      .then((data:any) => data && data.userSettings as UserSettings)
      .then((user:UserSettings) => {
        dispatch(setUserSettings(user));
      })
      .catch(console.error);
  };
}

export function fetchUserVerification () {
  return (dispatch:Dispatch, getState:any) => {
    return gql(`
      usersMe{
        name
        email
        id
        emailVerified
        walletAssociated
        address
      }
    `)
      .then((data:any) => data && data.usersMe as UserVerification)
      .then((user:UserVerification) => {
        clientStorage.save(StorageKey.USER_VERIFIED, user);
        dispatch(setUserVerification(user));
      })
      .catch(console.error);
  };
}