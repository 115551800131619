
export function getEmailData(email: string): any {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errorMsg = '';
  let valid = true;
  if (email.length === 0) {
    errorMsg = 'Please enter Email';
  } else if (!emailRegex.test(email)) {
    errorMsg = 'Enter valid email address';
    valid = false;
  } else {
    errorMsg = '';
  }

  return {errorMsg: errorMsg, valid: valid}
}

export function getPasswordData(password: string): any {
  let errorMsg = '';
  let valid = true;

  if (password.length === 0) {
    errorMsg = 'Please enter Password and length must be 8 characters';
  } else if (password.length < 8) {
    errorMsg = 'password must be minimum 8 characters';
  } else {
    errorMsg = '';
  }

  return {errorMsg: errorMsg, valid: valid}
}

export function getNameData(name: string, str: string): any {
  let errorMsg = '';
  let valid = true;

  if (name.length === 0) {
    errorMsg = `Please enter ${str} Name`;
  } else {
    errorMsg = '';
  }

  return {errorMsg: errorMsg, valid: valid}
}