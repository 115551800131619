import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, Button, Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { COLOR_WINE,COLOR_PRIMARY } from '../../helpers/styles';
import { isLoggedIn, logout } from '../../helpers/user';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  hamburger : {
    color: COLOR_PRIMARY,
    width: '50px',
    height: '50px',

    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(50),
    },
  },
}), { name: Hamburger.name });

export default function Hamburger() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignup = () => {
    history.push('/signup');
  };

  const handleProfile = () => {
    history.push('/profile');
  }

  const handleLogin = () => {
    history.push('/login');
  };

  const handleSignOut = () => {
    logout();
    history.push('/login');
  };

  return (
    <div>
      <Button className={classes.hamburger} onClick={handleClick}>
        <AccountCircleIcon/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        { isLoggedIn() ?
          <>
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            <MenuItem onClick={handleProfile}>My profile</MenuItem>
          </>
        :
          <>
            <MenuItem onClick={handleLogin}>Sign In</MenuItem>
            <MenuItem onClick={handleSignup}>Create account</MenuItem>
          </>
        }
      </Menu>
    </div>
  );
}