import * as React from "react";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ellipseAddress, getChainData } from "../../helpers/utilities";
import gql from '../../helpers/gql';
import Modal from '../Modal/Modal';
import * as clientStorage from '../../helpers/client-storage';
import { StorageKey } from '../../helpers/client-storage';

import './Header.css';

export default function Header(props:any) {
  const userInfo = clientStorage.get(StorageKey.USER);
  const history = useHistory();
  const { connected, address, chainId, killSession } = props;
  const activeChain = chainId ? getChainData(chainId).name : null;

  return (
    <div className="header">
      {(connected && activeChain) && (
        <div className="active-chain">
          <span>{`Connected to`}</span>
          <strong>{activeChain}</strong>
        </div>
      )}
      {address && address.length && (
        <div className="active-account">
          <strong className="account-address" title={address}>{ellipseAddress(address)}</strong>
          <div className="disconnect" onClick={killSession}>
            Disconnect
          </div>
        </div>
      )}
    </div>
  );
};
