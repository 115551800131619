import * as React from 'react';
import { handleSignificantDecimals, convertAmountFromRawNumber } from '../../helpers/bignumber';
import eth from '../../assets/eth.svg';
import xdai from '../../assets/xdai.png';

import './AccountAssets.css';

const AssetRow = (props: any) => {
  const { asset, balance } = props;
  const nativeCurrencyIcon =
    asset.symbol && asset.symbol.toLowerCase() === 'eth'
      ? eth
      : asset.symbol && asset.symbol.toLowerCase() === 'xdai'
      ? xdai
      : null;

  return (
    <div className="asset-row">
      <div className="left">
        {nativeCurrencyIcon ? (
          <img
            src={nativeCurrencyIcon}
            alt="nativeCurrencyIcon"
            height="20px"
            width="20px"
          />
        ) : (
          <img
            src={`https://raw.githubusercontent.com/TrustWallet/tokens/master/tokens/${asset.contractAddress.toLowerCase()}.png`}
            alt="nativeCurrencyIcon"
            height="20px"
            width="20px"
          />
        )}
        <div className="asset-name">{asset.name}</div>
      </div>
      <div className="right">
        <div className="asset-balance">
          {`${handleSignificantDecimals(convertAmountFromRawNumber(balance), 8)} ${
            asset.symbol
          }`}
        </div>
      </div>
    </div>
  );
};

export default AssetRow;
