import clsx from 'clsx';
import * as React from 'react';

import './Modal.css';

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  toggleModal: any;
  className?: string;
}

class Modal extends React.Component<ModalProps> {
  public lightbox?: HTMLDivElement | null;

  public toggleModal = async () => {
    const d = typeof window !== "undefined" ? document : "";
    const body = d ? d.body || d.getElementsByTagName("body")[0] : "";
    if (body) {
      if (this.props.show) {
        body.style.position = "";
      } else {
        body.style.position = "fixed";
      }
    }
    this.props.toggleModal();
  };

  public render() {
    const { children, show, className } = this.props;
    return (
      <div
        className={clsx('light-box', !show && 'hidden', className)}
        // className={`light-box ${!show && 'hidden'}`}
        ref={c => (this.lightbox = c)}
      >
        <div className="modal-container">
          <div className="hit-box" onClick={this.props.toggleModal} />

          <div className="card">
            <span className="close-button" onClick={this.props.toggleModal} />
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
