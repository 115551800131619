//@ts-nocheck
import React from 'react';

import PhoneVerifyLG from './PhoneVerify.lg';
import PhoneVerifySM from './PhoneVerify.sm';

export const PhoneVerify = (props: any) => {

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

      if(width <= 959){
          return <PhoneVerifySM {...props} />
      } else {
        return <PhoneVerifyLG {...props} />
      }
}