import React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import Dialog from './Dialog';
import {
  COLOR_LIGHT_GRAY,
  COLOR_WINE,
  COLOR_DARK_GRAY,
  FONT_NUNITO,
  COLOR_PRIMARY
} from '../../helpers/styles';
import displayRazorpay from '../../helpers/razorPay';

const useStyles = makeStyles(theme => ({
  underline: {
    textDecoration: 'underline',
  },
  laraText: {
    color: COLOR_PRIMARY,
    fontWeight: 700,
  },
  table: {
    borderRadius: '20px',
    fontFamily: FONT_NUNITO,
    padding: theme.spacing(2),
  },
  cellHead: {
    borderBottom: 'none',
    color: COLOR_DARK_GRAY,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    padding: theme.spacing(0.5, 2),
  },
  cell: {
    borderBottom: 'none',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    padding: theme.spacing(0.5, 2),
  },
  payText: {
    borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
    padding: theme.spacing(1.5, 0),
  },
  grey: {
    color: COLOR_DARK_GRAY,
  },
  updateModalSize: {   /////// if we want to send conditional some class for future use
    // maxWidth: '400px !important',
  },

}), { name: DialogContainer.name });

export default function DialogContainer (props:any) {
  const { openDialog, hideHeaderTable, closeDialog, fiatAssest, successCB, failureCB, dismissCB } = props;
  const classes = useStyles();
  // const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const check = true;   ////////// Saved for buyNow & future use
  //const check = false;   ////////// Saved for success-lg & future use

  function openRazorpay() {
    closeDialog('razorpay');
    displayRazorpay(fiatAssest, successCB, failureCB, dismissCB);
  }
  return (
    <>
      <Dialog
        className={classes.updateModalSize}
        open={openDialog}
        onClose={() => closeDialog('reject')}

        // title=""         ////////// Saved for success-lg condition & future use
        // subTitle="Yay! 🎉"
        // text={<span>You successfully purchased <span className={classes.underline}>ASSET#1</span> from <span className={classes.laraText}>Lara's Memorabilia</span> </span>}

        // title="Checkout"
        // subTitle="You are about to purchase ASSET#1 from Lara's Memorabilia"
        // text={<span>Once the purchase is successfull, the assets will be transfered to the <span className={classes.laraText}> abcdefghijklmnop </span></span>}
        // actions={[
        //   {
        //     name: 'I understand, continue',
        //     onClick: () => {},
        //   },
        //   {
        //     name: 'Cancel',
        //     onClick: () => {},
        //   }
        // ]}

        title="Razorpay"
        subTitle="Your transcation is in process. Please confirm or Reject"
        actions={[
          {
            name: 'Confirm',
            onClick: () => openRazorpay(),
          },
          {
            name: 'Reject',
            onClick: () => closeDialog('reject'),
          }
        ]}
      >
        { !check  ?
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellHead}>Status</TableCell>
                <TableCell className={classes.cellHead}>Transaction ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell className={clsx(classes.cell, classes.laraText)}>Processing</TableCell>
                <TableCell className={classes.cell}>abcdefghijklmnop</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        :

        <TableContainer>
          { !hideHeaderTable ?
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell  align="left" className={clsx(classes.cell, classes.payText)}>0.0001</TableCell>
                    <TableCell  align="right" className={clsx(classes.cell, classes.payText)}>ETH</TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                <TableRow >
                  <TableCell  align="left" className={clsx(classes.cell, classes.payText, classes.grey)}>You will pay</TableCell>
                  <TableCell  align="right" className={clsx(classes.cell, classes.payText)}>0.0001 ETH</TableCell>
                </TableRow>
            </TableBody>
          </Table>
          :

          <Table>
            <TableBody>
            { fiatAssest &&
              <TableRow>
                <TableCell  align="left" className={clsx(classes.cell, classes.payText, classes.grey)}>You will pay</TableCell>
                <TableCell  align="right" className={clsx(classes.cell, classes.payText)}>{fiatAssest.amount} {fiatAssest.currency}</TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        }
        </TableContainer>
      }
      </Dialog>
    </>
  );
}