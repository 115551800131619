//@ts-nocheck
import React from 'react';
import VerificationLG  from './Verification.lg';
import VerificationSM from './Verification.sm';
export const Verification = (props: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  
      if(width  <= 959){
          return <VerificationSM {...props} />
      } else {
        return <VerificationLG {...props} />
      }
}