import React from 'react';
import {Page} from './Pages';
import Dashboard from '../components/Dashboard/Dashboard';


export default function DashboardPage () {
  return (
    <Page>
      <Dashboard />
    </Page>
  )
}