import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles, fade, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Avatar } from '@material-ui/core';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import {
  COLOR_PRIMARY,
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY,
} from '../../helpers/styles';
import Hamburger from './Hamburger';

interface NavBarProps {
  sideDrawerOpen: boolean;
  onMenuButtonClick: (open: boolean) => void;
}

export const NAVBAR_HEIGHT = 90;

const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 999,
  },

  drawerControlContainer: {
    width: '71px',
    height: '64px',
    background: COLOR_WHITE,
  },

  appBar: {
    left: 0,
    right: 'auto',
    width: '100%',
    background: COLOR_WHITE,
    color: COLOR_PRIMARY,
    position: 'fixed',
    top: '0',
  },
  grow: {
    flexGrow: 1,
  },

  toolbar: {
    minHeight: `${NAVBAR_HEIGHT}px`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuIcon: {
    width: '22px',
    height: '22px',
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
    position: 'relative',

    '&:before': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      top: '5px',
      left: 0,
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '2px',
      background: COLOR_DARK_GRAY,
      position: 'absolute',
      bottom: '5px',
      left: 0,
    },
  },

  actions: {
    display: 'flex',
  },
  action: {
    overflow: 'visible',
    position: 'relative',
    marginLeft: theme.spacing(1),
    transition: 'color 0.3s',
    color: COLOR_PRIMARY,

    '&:first-of-type': {
      marginLeft: '0',
    },

    '&:before': {
      content: '""',
      display: 'none',
      width: '100%',
      height: '5px',
      position: 'absolute',
      bottom: '-34px',
      left: 0,
      background: COLOR_PRIMARY,
    },

    '&:hover': {
      textDecoration: 'none',
    },
  },
  actionSelected: {
    '&:before': {
      display: 'block',
    },

    '& $actionIcon': {
      color: COLOR_PRIMARY,
    },
  },
  actionIcon: {
    flexShrink: 0,
    width: '22px',
    height: '22px',
    marginRight: 0,
    color: COLOR_TEXT,
    transition: 'color 0.3s',

    'a:hover &': {
      color: COLOR_PRIMARY,
    },
  },
  actionText: {},

  title: {
    cursor: 'pointer',
  },
  gorw: {
    flexGrow: 1,
  },
}), { name: NavBar.name });

export default function NavBar (props:NavBarProps) {
  const {
    sideDrawerOpen = true,
    onMenuButtonClick,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <nav className={clsx(classes.navBar)}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={() => history.push('/')}
          >
            ECB MARKETPLACE
          </Typography>

          <div className={classes.grow} />
          <div className={classes.actions}>
            {/* <IconButton className={classes.action} onClick={() => history.push('/wallet-connect')}> */}
            {/* <div style={{display:'flex', flexDirection:'column', justifyContent:"center",alignContent:"center", fontSize:"16px"}}>
                  <div style={{display:"flex", justifyContent:"center"}}> */}
              {/* <Avatar>ECB</Avatar> */}
            {/* </IconButton> */}
            <Hamburger />
          </div>

          {/* <div className={classes.actions}>
            <IconButton className={classes.action} onClick={() => history.push('/wallet-connect')}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:"center",alignContent:"center", fontSize:"16px"}}>
                  <div style={{display:"flex", justifyContent:"center"}}>
              <AccountBalanceWalletOutlinedIcon />
              </div>

                    Wallet
                  </div>
            </IconButton>
            {/* <Action
              className={classes.action}
              selectedClassName={classes.actionSelected}
              href='/dashboard'
            >
              <span className={classes.actionText}>Dashboard</span>
            </Action>
          </div> */}
        </Toolbar>
      </AppBar>
    </nav>
  );
}
