//@ts-nocheck
import React, { useState } from "react";

import { EmailVerify, WalletAssociate, PhoneVerify } from "../components";
import IconTimeline from "../components/IconTimeline";

interface VerificationProps {}

const timelineSteps = [
  "Email Verification",
  "Phone Verification",
  "Wallet Association",
];

const walletData = [
  {
    key: "meramask",
    label: "Metamask",
    logo: "https://i.ibb.co/9q6z48b/image.png",
    img: "https://metamask.io/images/mm-logo.svg",
    href: "https://metamask.io",
  },
  {
    key: "walletConnect",
    label: "Wallet Connect",
    logo: "https://i.ibb.co/ck7VYbm/image-1.png",
    img: "https://i.ibb.co/qx4pvNj/image-3.png",
    href: "https://walletconnect.org/",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
  {
    key: "wallet",
    logo: "",
    img: "",
  },
];

export default function VerificationLG(props: VerificationProps) {
  const [activeStep, setActiveStep] = useState<number>(0);

  const onEmailVerifyClick = (emailOTP) => {
    console.log(`onEmailVerifyClick:`, emailOTP);
    setActiveStep(activeStep + 1);
  };

  const onPhoneVerifyClick = (phoneOTP) => {
    console.log(`onPhoneVerifyClick:`, phoneOTP);
    setActiveStep(activeStep + 1);
  };

  const renderItems = () => {
    switch (activeStep) {
      case 0:
        return <EmailVerify onEmailVerifyClick={onEmailVerifyClick} />;
      case 1:
        return <PhoneVerify onPhoneVerifyClick={onPhoneVerifyClick} />;
      case 2:
        return (
          <WalletAssociate onWalletSubmit={() => {}} walletData={walletData} />
        );
    }
  };

  return (
    <IconTimeline
      steps={timelineSteps}
      active={activeStep}
      renderItem={renderItems()}
    />
  );
}
