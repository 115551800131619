import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import WalletConnect from '@walletconnect/client';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  SideDrawer
} from '../../components/SideDrawer';
import NavBar, { NAVBAR_HEIGHT } from '../../components/NavBar/NavBar';
import { walletConnect } from '../../redux/actions/walletAction';
import { getWalletFromLocalStorage } from '../../redux/reducers/walletReducer';
import {
  WALLET_CONNECT_BRIDGE,
} from '../../helpers/constants';
import { State } from '../../redux/reducers';
import { COLOR_WHITE, FONT_NUNITO } from '../../helpers/styles';
import {Footer}  from "../../components/Footer";

interface PageProps {
  children:any;
  wallet:any;
  walletConnect: (wallet:any) => void;
}
const SIDE_DRAWER_WIDTH = 90;
const useStyles = makeStyles(theme => ({
  page: {
    width: '100%',
    display: 'flex',
    background: COLOR_WHITE,
    fontFamily: FONT_NUNITO,
  },
  pageContainer: {
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    paddingTop: `${NAVBAR_HEIGHT}px`,
  },
  container: {
    // width: `calc(100vw - ${SIDE_DRAWER_WIDTH}px)`,
    // marginLeft: `${SIDE_DRAWER_WIDTH}px`,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxSizing: 'border-box',
    transition: 'margin 0.3s, width 0.3s',
    padding: theme.spacing(2, 0),
  },
  withOpenDrawer: {
  },
}), { name: PageSM.name });

function PageSM (props:PageProps) {
  const {
    children,
    wallet,
    walletConnect,
  } = props;
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  useEffect(() => {
    if (!wallet || !wallet.connected) {
      const connector = new WalletConnect({ bridge: WALLET_CONNECT_BRIDGE });
      walletConnect(connector);
    }
  }, []);
  return (
    <div className={classes.page}>
      <SideDrawer
        sideDrawerOpen={isDrawerOpen}
        onMenuButtonClick={(isOpen:boolean) => setIsDrawerOpen(isOpen)}
      />

      <main className={classes.pageContainer}>
        <NavBar
          sideDrawerOpen={isDrawerOpen}
          onMenuButtonClick={(isOpen:boolean) => setIsDrawerOpen(isOpen)}
        />

        <Container
          className={clsx(classes.container, isDrawerOpen && classes.withOpenDrawer)}
          maxWidth={false}
        >
          {children}
          <Footer/>
        </Container>

      </main>
    </div>
  );
}

const mapStateToProps = (state:State) => ({
  wallet: state.wallet,
});

const mapDispatchToProps = (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      walletConnect,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageSM);