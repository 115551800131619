import axios from 'axios';
import gql from './gql';
import { WEI_CURRENCY, ETH_CURRENCY, WEI_RATIO } from './constants';
import { getByPlaceholderText } from '@testing-library/dom';

export async function fetchTransactionObject(data:any):Promise<any> {
  const response = await axios({
    method: 'POST',
    url: 'https://production.monarcha.in/transaction/create',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }).catch((err)=>err.response);

  const { transaction } = response.data;
  return transaction;
}

export async function buyTokenAttempt(data:any, address:string):Promise<any> {
  const response = await gql(`
      mutation buytokenattempt{
        blockchainControllerAttemptBuyToken(
          attemptBuyTokenInput:{
            userid: "${data.minter}"
            tokenid: "${data.tokenid}"
            from: ""
            to: "${address}"
            price:{
              unit: ${data.price.unit}
              currency: "${WEI_CURRENCY}"
              ratio: ${WEI_RATIO}
            }
          }
        )
      }
    `);

    return response.blockchainControllerAttemptBuyToken;
}

export async function changeProductStatus(data:any, status:string):Promise<any> {
  const response = await gql(`
    mutation changestatus{
      nftassetControllerSetPending(
        id:"${data.tokenid}"
        status:"${status}"
      )
    }
  `);

  return response.nftassetControllerSetPending;
}

export async function registerStatus(product:any, successToken:any):Promise<any> {
  const response = await gql(`
    mutation registerpurchase{
      blockchainControllerRegisterPurchase(
        registerPurchaseInput:{
          tokenindex: ${product.tokenIndex}
          buyTokenHash: "${successToken}"
        },where:{
          tokenid: "${product.tokenid}"
        }
      )
    }
  `);

  return response.blockchainControllerRegisterPurchase;
}

export async function cancelPurchase(data:any):Promise<any> {
  const response = await gql(`
    mutation cancelPurchase{
      blockchainControllerCancelPurchaseInit(
        cancelPurchaseInitInput:{},
        where:{
          tokenid:"${data.tokenid}"
        }
      )
    }
  `);

  return response.blockchainControllerCancelPurchaseInit;
}