import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import logo from "../../assets/images/cap.jpg";

const useStyles = makeStyles(theme => ({
  razorPayConatiner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '100px 0px',
  },
  logoImg: {
    width: '50px',
    height:'50px',
  }
}), { name: RazorPayPayment.name });

export default function RazorPayPayment (props:any) {
  const classes = useStyles();

  function loadScript(src:any) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    const amount = '5000';
    const currency = 'INR';

    // credential: {
    //   keyId: 'rzp_test_CzuLdmG0xAMyRD',
    //   keySecret: 'wrKWAMHDFGn1nZ7zG7DoeUx2'
    // }

    const options = {
      key: "rzp_test_CzuLdmG0xAMyRD", // Enter the Key ID generated
      amount: amount.toString(),
      currency: currency,
      name: "Razorpay test.",
      description: "Test Transaction",
      image: { logo },
      handler: async function (response:any){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
          name: "Razorpay Dey",
          email: "Razorpay@example.com",
          contact: "9999999999",
      },
      notes: {
          address: "Razorpay test Office",
      },
      theme: {
          color: "#61dafb",
      },
      retry: {
        enabled: false,
        max_count: 0,
      },
      "modal": {
        "ondismiss": function(){
            console.log('Checkout form closed');
        }
      }
    };

    const paymentObject =  new (window as any).Razorpay(options);

    paymentObject.on('payment.failed', function (response:any){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
      paymentObject.close();
      window.location.reload();
    });

    paymentObject.open();
  }

  return (
    <div className={classes.razorPayConatiner}>
      <img className={classes.logoImg} src={logo} alt="logo" />
      <p>RazorPay experiment!</p>
      <button onClick={displayRazorpay}>
          Pay ₹50
      </button>
    </div>
  )
}
