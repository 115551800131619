export enum StorageKey {
  TOKEN = 'user-token',
  USER = 'user-data',
  USER_SETTINGS = 'user-settings',
  USER_VERIFIED = 'user-verification',
  WALLET_ADDRESS = 'wallet-address',
  WALLET_ACCOUNT = 'walletconnect'
}

export function save (key:StorageKey, data:any) {
  if (!data) return;

  if (typeof data === 'object') {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
}

export function get (key:StorageKey):any {
  let valueToReturn;

  const stringValue = localStorage.getItem(key);
  try {
    valueToReturn = JSON.parse(stringValue || '');
  } catch (error) {
    valueToReturn = stringValue;
  }
  return valueToReturn;
}

export function remove (key:StorageKey) {
  localStorage.removeItem(key);
}

export function clearAll() {
  localStorage.clear();
}
