import React from 'react';
import {Page} from './Pages';
import TransactionHistory from '../components/TransactionHistory/TransactionHistory';

export default function TransactionHistoryPage () {
  return (
    <Page>
      <TransactionHistory />
    </Page>
  )
}