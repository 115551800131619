// ProductDetails
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer,TableHead, TableRow, Paper } from '@material-ui/core';
import {
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_PRIMARY,
  COLOR_WINE,
  COLOR_TEXT,
  COLOR_LIGHT_GRAY
} from '../../helpers/styles';
import clsx from 'clsx';

interface TransactionTableProps {
  myAssets: any;
}

const useStyles = makeStyles(theme => ({
  transactionHistoryWrapper: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageHeader: {
    color: COLOR_PRIMARY,
    padding: theme.spacing(6, 0),
  },
  headerTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 800,
  },
  subHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
  },
  paper: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    padding: theme.spacing(3),
    display: 'flex',
  },
  listTitle: {
    color: COLOR_TEXT,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 700,
  },
  separator: {
    margin: theme.spacing(3, 0),
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLOR_LIGHT_GRAY,
    }
  },
  tableCell: {
    fontSize: '16px',
  },
  headCell: {
    fontWeight: 600,
  },
  noData: {
    backgroundColor: COLOR_LIGHT_GRAY,
    padding: '20px 0',
    textAlign: 'center',
    fontSize: '16px',
  },
  assetImage: {
    height: '100px',
    width: '100px'
  }
}), { name: TransactionTable.name });

export default function TransactionTable(props:TransactionTableProps) {
  const classes = useStyles();
  const { myAssets } = props;

  const history = useHistory();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={clsx(classes.tableCell, classes.headCell)}>Asset Preview</TableCell>
            <TableCell align="center" className={clsx(classes.tableCell, classes.headCell)}>Asset Name</TableCell>
            <TableCell align="center" className={clsx(classes.tableCell, classes.headCell)}>Status</TableCell>
            <TableCell align="center" className={clsx(classes.tableCell, classes.headCell)}>Token Id</TableCell>
            {/* <TableCell align="center">To Account</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {myAssets && !!myAssets.length && (
            myAssets
            .sort((a:any, b:any) => {return ((new Date(b.lastTrxDate)).getTime() - (new Date(a.lastTrxDate)).getTime());})
            .map((asset:any) => (
              <TableRow
                key={asset.tokenid}
                onClick={() =>history.push(`/product-details/${asset.tokenid}`)}
                className={classes.tableRow}
              >
                <TableCell align="center" className={classes.tableCell}>
                  <img src={asset.locationUrl} alt='asset image' className={classes.assetImage} />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>{asset.assetname}</TableCell>
                <TableCell align="center" className={classes.tableCell}>{asset.status}</TableCell>
                <TableCell align="center" className={classes.tableCell}>{asset.tokenid}</TableCell>
                {/* <TableCell align="center">{asset.account}</TableCell> */}
              </TableRow>
            ))
          )}
          {myAssets && !myAssets.length && (
            <TableRow key='no-data'>
              <TableCell colSpan={4}>
                <div className={classes.noData}>No Data Available</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}