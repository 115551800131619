export const FONT_NUNITO = 'Nunito, Helvetica, Arial, sans-serif';

export const COLOR_WINE = '#7b0041';
export const COLOR_LIGHT_WINE = '#ffeef7';
export const COLOR_WHITE = '#ffffff';
export const COLOR_TEXT = '#070a13';
export const COLOR_DARK_GRAY = '#777777';
export const COLOR_LIGHT_GRAY = '#f0f0f0';
export const COLOR_SHADOW = 'rgb(123 0 65 / 5%)';
export const COLOR_GREEN = '#007b3a';
export const COLOR_TERARY = '#D5E6E4';
export const COLOR_PRIMARY = '#002F52';
export const COLOR_SECONDARY = '#005699';
export const COLOR_BACKGROUND = '#10102F';
export const COLOR_BLUE = '#197DC2';
export const BACKGROUND_GRADIENT = `linear-gradient(to bottom right, ${COLOR_PRIMARY}, ${COLOR_BACKGROUND})`;
export const DISABLED_COLOR = "#ADD8E6";
export const ERROR_COLOR = '#f44336';

export const COLOR_LIGHT_GRAY_2 = "#ccc"
export const COLOR_LIGHT_GRAY_3 = "#eaeaf0"

// export const COLOR_PRIMARY = '#1a4677';
// export const COLOR_SECONDARY = '#bad5ea';
export const COLOR_WINE_1 = '#a40057';
export const COLOR_WINE_2 = '#52002b';
export const COLOR_GREY = '#d2d2d2';
export const PAPER_WHITE = '#f7f7f7';

// timeline connector color

export const COLOR_CONNECTOR_1 = "rgb(242,113,33)";
export const COLOR_CONNECTOR_2 = "rgb(233,64,87)";
export const COLOR_CONNECTOR_3 = "rgb(138,35,135)";


// timeline DOTS color

export const COLOR_DOT_1="rgba(209,0,116,1)";
export const COLOR_DOT_2=" rgba(110,44,107,1)";
export const COLOR_DOT_3=" rgb(219, 86, 103)";
export const COLOR_DOT_4="rgb(138,35,135)";


//box shadow

export const BOX_SHADOW_1 ="rgba(0,0,0,.25)";

export const COLOR_HOME_TEXT = '#847A90';
export const COLOR_SIGNUP_BG = '#bad5ea';

//border
export const COLOR_BORDER = "rgba(0,0,0,.1)";

//score dot

export const COLOR_SCORE_BG = "rgba(0, 123, 58, 0.2)";
export const COLOR_LOGIN_BTN = '#bad5ea';
export const COLOR_LOGIN_BG = '#005699';
