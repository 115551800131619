//@ts-nocheck
import React from "react";
import { makeStyles} from '@material-ui/core/styles';
import Action from './Action';
import { COLOR_PRIMARY, COLOR_TEXT, COLOR_HOME_TEXT, COLOR_WHITE, FONT_NUNITO} from '../../helpers/styles';

const useStyles = makeStyles(theme => ({
  menuWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuButton: {
    backgroundColor: 'transparent',
    borderRadius: '24px',
    color: COLOR_TEXT,
    display: 'flex',
    fontFamily: FONT_NUNITO,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    justifyContent: 'center',
    padding: '10px 40px',
    '&:hover': {
      border: `2px solid ${COLOR_PRIMARY}`,
      color: COLOR_PRIMARY,
      textDecoration: 'none'
    }
  },
}), { name: LandingMenu.name });


export default function LandingMenu(props:any) {
  const classes = useStyles();

  return (
    <div className={classes.menuWrapper}>
      <Action className={classes.menuButton} href='/login'>
        Sign In
      </Action>
      <Action className={classes.menuButton} href='/signup'>
        Sign Up
      </Action>
    </div>
  );
}
