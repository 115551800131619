// mintasset.ts
export enum Status {
  PENDING = 'Pending',
  CREATED = 'Created',
  LISTED = 'Listed',
  SOLD = 'Sold',
};

interface Category {
  name: string;
  parent: string;
  category: string;
}

interface MintAssetPrice {
  unit: number;
  currency: string;
  ratio: number;
}

export default interface MintAsset {
  tokenid: number;
  tokenIndex: number;
  assetname: string;
  category: Category[];
  locationUrl: string;
  price: MintAssetPrice;
  status: Status;
  attrributes: any;
  lastTrxDate: string;
  creationDate: string;
  minter: string;
  account: string;
}